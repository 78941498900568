// React
import React, {useState, useCallback, useRef, useEffect } from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

// 3P
import { t } from 'i18next';
import ReactGA from 'react-ga4';

// Common
import {headersInfo} from '../data/headerData';
import Configs from '../Configs';

// Icons
// Modules icons
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai';
import {IoLogOut, IoPersonAdd, IoPulseSharp, IoSettings } from "react-icons/io5";
import {BsHouseFill, BsPersonBadgeFill, BsFillInboxFill, BsLadder} from "react-icons/bs";
import {FaBalanceScale, FaRegBell } from "react-icons/fa";
import {CgPerformance} from "react-icons/cg";
import {SiCrowdsource} from "react-icons/si";
import {GiArcheryTarget, GiPalmTree, GiOrganigram, GiPlantSeed, GiPoliceOfficerHead} from 'react-icons/gi';
import {FaPeopleArrows, FaAward} from 'react-icons/fa';
import {PiPlantBold} from 'react-icons/pi';
import { BsStars } from 'react-icons/bs'; // AI icon


// ---------------------------------------------------------------------------------------------------
const Header = () => {
    // To control page navigation
    const location = useLocation();
    const navigate = useNavigate();
    const submenuRef = useRef();
    const navBarRef = useRef();
    const navBarRefMobile = useRef();
    const submenuRefMobile = useRef();
    
    // To control mobile navBar
    const [navbarOpen, setNavbarOpen] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const [productSubMenuOpen, setProductSubMenuOpen] = useState(false);
    const [verticalsSubMenuOpen, setVerticalsSubMenuOpen] = useState(false);
    const [solutionsSubMenuOpen, setSolutionsSubMenuOpen] = useState(false);


    // Logging in Dev
    if (Configs.devEnvironment) console.log ("[newHeader.js][main()] - location.pathname: ", location.pathname, location.pathname.split("/"));

  // ------------------------------------------------------------------------------------------
  // useEffect
  // ------------------------------------------------------------------------------------------
  useEffect(() => {
    // Attach click event listener
    document.addEventListener('mousedown', handleClickOutside);
  
    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // ------------------------------------------------------------------------------------------
  // Frontend functions
  // ------------------------------------------------------------------------------------------
  async function handleDemoButton (e) {
    // Avoid button default behavior - refresh page
    e.preventDefault();

    // Log GA4 event
    ReactGA.event({
      category: "conversion",
      action: `Demo button clicked: ${e.target.id}`,
      label: "Header - Demo button clicked", // optional
    });

    // Navigate to contact page
    navigate("/contact/");
  }  

  const renderIcon = useCallback((element) => {
    switch (element) {
        case "Home":
        return (
            <BsHouseFill />
          );
          case "Recruitment":
            return (
              <SiCrowdsource />
            );
          case "Onboarding":
        return (
            <IoPersonAdd />
        );
        case "Performance-Review":
        return (
            <CgPerformance />
        );
        case "Calibration":
          return (
              <FaBalanceScale />
          );
        case "1on1":
        return (
            <FaPeopleArrows />
        );
        case "Recognition":
          return (
              <FaAward />
          );
        case "Pulse":
        return (
            <IoPulseSharp />
        );
        case "Time-off":
        return (
          <GiPalmTree />
        );
        case "Profile":
        return (
           <BsPersonBadgeFill />
        );
        case "Inbox":
        return (
            <BsFillInboxFill />  
        );
        case "Organigram":
        return (
            <GiOrganigram />
        );
        case "Goals":
        return (
            <GiArcheryTarget />
        );
        case "Development":
        return (
            <PiPlantBold />
        );
        case "Career":
        return (
            <BsLadder />
        );
        case "Admin":
        return (
            <GiPoliceOfficerHead/>
        );
    }
}, []);

const handleClickOutside = (event) => {
    if (submenuRef.current && !navBarRef.current.contains(event.target) && submenuRefMobile.current && !navBarRefMobile.current.contains(event.target)) {
      setActiveSubMenu(null); // Close submenu
    } 
  };

const handleClickOnMenuOption = (event) => {
    if (Configs.devEnvironment) console.log ("[newHeader.js][handleClickOnMenuOption()] ", activeSubMenu, headersInfo[event.target.id]);
    // Decide to close or open the submenu
    if (activeSubMenu === null) {
        // It was inactive -> toggle On
        setActiveSubMenu(headersInfo[event.target.id]?.submenu)

    } else {
        // it was active -> toggle Off
        setActiveSubMenu(null);
    }
}


// ---------------------------------------------------------------------------------------------------
// Page return methods
// ---------------------------------------------------------------------------------------------------  
            return (
                <React.Fragment>
                        <nav ref={navBarRef} className=" DESKTOP-HEADER hidden lg:block flex flex-row top-0 z-50 h-20 fixed w-full
                        items-center justify-between p-4 pl-12 pr-12 space-y-4
                        text-sm border-b bg-white shadow-lg">
                            {/* Main nav menu */}
                            <div className='flex flex-row w-full items-center justify-between'>
                                <div className="flex flex-col items-center p-4">
                                    <Link to="/">
                                        <div className="flex items-center container w-[75px] max-w-[75px]
                                                        max-h-[20px] h-[20px]">
                                            <img className="flex object-contain" 
                                                alt="Kincode App - Culture-based employee engagement platform"
                                                src ="/assets/images/kincode-logo.png"
                                            />
                                            <span className="font-mono m-4 text-2xl">{t('kincode')}</span>
                                        </div>
                                    </Link>
                                </div>

                                <div className="flex flex-row items-center justify-between space-x-8 text-gray-800 ">
                                    {headersInfo.map((item, index) => (
                                        item?.submenu ?
                                            <div className={'flex flex-col items-start justify-between py-2 cursor-pointer hover:font-bold hover:border-b-4 hover:border-green-200'
                                            + ((location?.pathname?.split("/")?.includes(item?.id) ) ? ' border-b-4 border-green-200 ' : null  )}
                                            onClick={(event) => handleClickOnMenuOption(event)}
                                            key={index}
                                            id={index}
                                            >
                                                    {t(item.name)}
                                            </div>
                                        :
                                            <Link to={item.url}>
                                                <div className={'flex flex-row items-center justify-between py-2 hover:font-bold hover:border-b-4 hover:border-green-200 '
                                                + (location.pathname === item.url ? ' border-b-4 border-green-200 ' : null  )}
                                                onClick={(event) => handleClickOnMenuOption(event)}
                                                key={index}
                                                id={index}
                                                >
                                                    {t(item.name)}            
                                                </div>
                                            </Link>
                                    ))}
                                </div>

                                <div className='flex flex-row space-x-4 '>
                                    <a href="https://platform.kincode.app" target="_blank" rel="noreferrer" className='hover:font-bold hover:underline'>
                                        <div id="header-login-button" className='px-4 py-2 rounded  ' >
                                            {t('login')}
                                        </div>
                                    </a>

                                    <button id="header-cta-button" className='header-cta-button ' onClick={(e) => handleDemoButton(e)}>
                                        {t('cta')}
                                    </button>
                                </div>
                            </div>

                            {/* Hover menu */}
                            {activeSubMenu && activeSubMenu?.length > 0 ?
                                <div ref={submenuRef}  className='flex flex-row w-full border-b bg-white shadow-lg rounded-lg px-8 py-4'>
                                    <div className='flex flex-col'>
                                        <div className='flex flex-row items-start justify-between '>
                                            <div className='flex flex-row items-start justify-start space-x-8 w-full px-12 text-gray-800 '>
                                                {activeSubMenu?.map((section, index) => (
                                                    index !== 3 ?
                                                    <div className={'flex flex-col items-start justify-between py-2 px-12 '}
                                                    key={index}
                                                    >
                                                        
                                                        {section?.url ? 
                                                            <Link to={section.url}>
                                                                <span className={'font-black text-base lg:text-lg hover:underline '
                                                                + (location.pathname === section.url ? ' border-b-4 border-green-200 ' : null  )
                                                                }>
                                                                    {t(section?.sectionName)}
                                                                </span>
                                                            </Link>
                                                        :
                                                            <span className={'font-black text-base lg:text-lg'
                                                            + (location.pathname === section.url ? ' border-b-4 border-green-200 ' : null  )
                                                            }>
                                                                {t(section?.sectionName)}
                                                            </span>
                                                        }

                                                        <div className='flex flex-col items-start justify-between space-y-2 text-gray-800 mt-4'>
                                                            {section?.subSections?.map((subSection, index) => (
                                                                subSection?.url !== "" ?
                                                                    <Link to={subSection.url}>
                                                                        <div className={'flex flex-col items-start justify-between py-1 hover:underline hover:font-medium '
                                                                        + (location?.pathname === subSection?.url ? ' border-b-4 border-green-200 ' : null  )}
                                                                        key={index}
                                                                        >
                                                                            <div className='flex flex-row items-center space-x-2'>
                                                                                <span className='text-lg mr-2'>{renderIcon(subSection.icon)}</span>
                                                                                {t(subSection?.title)}
                                                                            </div>
                                                                        </div>
                                                                    </Link>

                                                                    :

                                                                    // Work in progress
                                                                    <div className='flex flex-col items-start justify-between py-1 text-gray-400' key={index}>
                                                                        <div className='flex flex-row items-center space-x-2'>
                                                                            <span className='text-lg mr-2'>{renderIcon(subSection.icon)}</span>
                                                                            {t(subSection?.title) + "  " + "🚧"}
                                                                        </div>  
                                                                    </div>
                                                                
                                                            ))}
                                                        </div>

                                                    </div>
                                                    :
                                                    null
                                                ))}
                                            </div>
                                        </div>
                                        {/* Submenu only for Basics features */}
                                        <div className='flex flex-row items-start justify-start space-x-8 w-full px-12 text-gray-800 '>
                                                {activeSubMenu?.map((section, index) => (
                                                    index === 3 ? 
                                                    <div className={'flex flex-col items-start justify-between py-2 px-12 '}
                                                    key={index}
                                                    >
                                                        <Link to={section.url}>
                                                                <span className={'font-black text-base lg:text-lg'
                                                                + (location.pathname === section.url ? ' border-b-4 border-green-200 ' : null  )
                                                                }>
                                                                    {t(section?.sectionName)}
                                                                </span>
                                                            </Link>

                                                        <div className='flex flex-col items-start justify-between space-y-2 text-gray-800 mt-4'>
                                                            {section?.subSections?.map((subSection, index) => (
                                                                <Link to={subSection.url}>
                                                                    <div className={'flex flex-col items-start justify-between py-1 hover:underline hover:font-medium '
                                                                    + (location.pathname === subSection.url ? ' border-b-4 border-green-200 ' : null  )}
                                                                    key={index}
                                                                    >
                                                                        <div className='flex flex-row items-center space-x-2'>
                                                                            <span className='text-lg mr-2'>{renderIcon(subSection.icon)}</span>
                                                                            {t(subSection?.title)}
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            ))}
                                                        </div>

                                                    </div>
                                                    :
                                                    null
                                                    
                                                    ))}
                                        </div>
                                    </div>

                                    {/* Kincode AI */}
                                    {/* <div className='flex flex-col items-center text-gray-800 bg-green-200 shadow w-1/4 rounded-lg p-2 -mt-1'>
                                        <Link to="../product/ai">
                                            <span className={'flex flex-row  items-center font-black text-base lg:text-lg hover:underline border-b-4 border-green-200 '}>
                                                <BsStars className='mr-2 text-violet-800'/> Kincode AI
                                            </span>
                                        </Link>
                                    </div> */}

                                </div>
                                
                            : null }

                    </nav>
                    <nav ref={navBarRefMobile} className="MOBILE-HEADER block lg:hidden flex flex-row top-0 z-50 h-20 fixed w-full
                    justify-between items-center p-4 rounded-tr-4xl rounded-br-4xl
                    text-sm border-b bg-white shadow-lg" >
                                
                                <div className="flex flex-row items-center">
                                    <Link to="/">
                                    <div className="flex container-sm ml-2 lg:ml-8 w-[150px] max-h-[75px]
                                                    lg:w-[150px] lg:h-[75px]">
                                        <img
                                            className="flex object-scale-down"
                                            alt="Kincode App - Culture-based employee engagement platform"
                                            src ="/assets/images/kincode-logo.png"
                                        />
                                    </div>
                                    </Link>
                                </div>

                                <div className="flex flex-row items-center justify-between">
                                                    <button 
                                                        onClick={() => setNavbarOpen(!navbarOpen)} 
                                                        className="flex items-center mr-4 px-3 py-2 
                                                        text-2xl text-black-800 
                                                        hover:text-gray-400">
                                                        { navbarOpen ?
                                                            <AiOutlineClose />
                                                        :
                                                            <AiOutlineMenu />
                                                        }
                                                    </button>

                                </div>

                                <div className={ navbarOpen ? " bg-white flex flex-col fixed inset-0 h-auto overflow-y-auto max-h-full py-4 space-y-8 " : " hidden "}>

                                                    <button 
                                                        onClick={() => setNavbarOpen(!navbarOpen)} 
                                                        className="flex justify-end text-right mr-4 px-3 py-2 
                                                        text-2xl text-black-800 
                                                        hover:text-gray-400">
                                                        { navbarOpen ?
                                                            <AiOutlineClose></AiOutlineClose>
                                                        :
                                                            <AiOutlineMenu></AiOutlineMenu>
                                                        }
                                                    </button>

                                                    <div className="flex flex-row w-full items-center border-b">
                                                            <div className='flex flex-col py-6 items-center w-full'>
                                                                    <Link to="/">
                                                                        <div className="flex items-center container w-[75px] max-w-[75px]
                                                                                        max-h-[20px] h-[20px]">
                                                                            <img className="flex object-contain" 
                                                                                alt="Kincode App - Culture-based employee engagement platform"
                                                                                src ="/assets/images/kincode-logo.png"
                                                                            />
                                                                        </div>
                                                                        <span className="flex font-mono mt-8 text-xl">
                                                                            {t('kincode')}
                                                                        </span>
                                                                    </Link>
                                                            </div>
                                                    </div>


                                                    <div className="flex flex-col items-start justify-between space-y-8 text-gray-800 mx-8 ">
                                                        {headersInfo.map((item, index) => (
                                                            // If the item has a submenu, then it's a section - withou a submenu, it's a link
                                                            item?.url ?

                                                                <Link to={item.url} className='flex flex-row w-full rounded-lg hover:bg-gray-200 py-2 px-2 w-full font-medium'>
                                                                        <div key={index} className={'flex flex-col items-start justify-between '
                                                                        + (location?.pathname === item?.url ? ' border-b-4 border-green-200 ' : null  )}
                                                                        id={index}
                                                                        onClick={(e) => handleClickOnMenuOption(e)}
                                                                        >
                                                                            {t(item.name)}
                                                                        </div>
                                                                </Link>

                                                            :

                                                                <div className='flex flex-col items-start justify-between space-y-4 w-full'ref={submenuRefMobile}>
                                                                    {/* Submenu title */}
                                                                    <div className={'flex flex-row w-full rounded-lg hover:bg-gray-200 py-2 px-2 w-full font-medium cursor-pointer ' 
                                                                    + (location?.pathname === item?.url ? ' border-b-4 border-green-200 ' : null  )}
                                                                        id={index}
                                                                        onClick={(e) => handleClickOnMenuOption(e)}>
                                                                        {t(item.name)}
                                                                    </div>
                                                                    {/* Submenu items */}
                                                                    {activeSubMenu?.length > 0 && <div className='flex flex-wrap items-start py-2 mx-6'>
                                                                        {activeSubMenu?.map((subSection, index) => (
                                                                            <div className='flex flex-col items-start justify-between py-2 mx-8'>
                                                                                {/* Subsection title */}
                                                                                <Link to={subSection.url}>
                                                                                    <div className={'flex flex-col items-start justify-between py-2 hover:font-bold hover:border-b-4 hover:border-green-200 mx-8 '}
                                                                                    key={index} id={index}>
                                                                                        {t(subSection?.sectionName)}
                                                                                    </div>
                                                                                </Link>
                                                                                {/* Subsection items */}
                                                                                {subSection?.subSections?.map((subSection, index) => (
                                                                                    <Link to={subSection.url}>
                                                                                        <div className={'flex flex-col items-start justify-between py-2 mx-12 hover:underline hover:font-medium '}
                                                                                        key={index} id={index}>
                                                                                            <div className='flex flex-row items-center space-x-2'>
                                                                                                <span className='text-lg mr-2'>{renderIcon(subSection.icon)}</span>
                                                                                                {t(subSection?.title)}
                                                                                            </div>
                                                                                        </div>
                                                                                    </Link>
                                                                                ))}
                                                                            </div>
                                                                        ))}
                                                                    </div>}
                                                                </div>
                                                            
                                                        ))}
                                                    </div>

                                                    <div className='flex items-center justify-center pt-20 '>
                                                        <button id="cta-button" className='header-cta-button ' onClick={(e) => handleDemoButton(e)}>
                                                            {t('cta')}
                                                        </button>
                                                    </div>
                                </div>
                </nav>
            </React.Fragment>
        );
  }
  
  export default Header;