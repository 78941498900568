export const testimonials = [
    {
            // Belisario
            quote: "testimonials.quote-1",
            name: "testimonials.name-4",
            img: "/assets/testimonialsImages/BelisarioMoneta.jpeg",
            companyImg: "/assets/customerLogos/Logo_Inversimply-01-B.png",
     }, {
            // Alexandre
            quote: "testimonials.quote-2",
            name: "testimonials.name-2",
            img: "/assets/testimonialsImages/AlexColombon.jpeg",
            companyImg: "/assets/customerLogos/Qaracter_new_Logo_Color_Dark.svg",
        // }, {
            // Fiorella
            // quote: "testimonials.quote-3",
            // name: "testimonials.name-3",
            // img: "/assets/testimonialsImages/Carol@2x.png",
            // companyImg: "/assets/customerLogos/New_TaxDown_Large.png",
        // }, {
            // Carol
            // quote: "testimonials.quote-1",
            // name: "testimonials.name-1",
            // img: "/assets/testimonialsImages/Carol@2x.png",
            // companyImg: "/assets/customerLogos/WoW_Logo@2x.png",
        }
  ];
  