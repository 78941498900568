// React
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

// Common
import Header from '../../common/newHeader';
import Footer from '../../common/footer';

// 3P
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

// Widgets
import Testimonials from '../../widgets/testimonials';
import CTA from '../../widgets/cta';
import CustomersWidget from '../../widgets/customers';
import TextAndImage from '../../widgets/textAndimage';

function Engage () {
  const location = useLocation();
  const {t} = useTranslation();


// ------------------------------------------------------------------------------------------
// Functions
// ------------------------------------------------------------------------------------------
useEffect (() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Product-Engage" });

    // Scroll to top
    window.scrollTo(0, 0);
  
  }, []);


  // ------------------------------------------------------------------------------------------
  // Render function
  // ------------------------------------------------------------------------------------------
  return(
      <React.Fragment>
        <Header/>

        <div className='flex flex-col items-center h-auto w-auto'>
            {/* Headline */}
            <div id="main" className='flex flex-col space-y-12 lg:space-y-0 lg:flex-row items-center lg:justify-center w-full px-8 lg:px-40 py-24 bg-violet-50'>
                {/* Text left-side */}
                <div className='flex flex-col items-start w-full lg:w-1/3 space-y-4'>
                    {/* Section title */}
                    <h1 className='text-left text-lg font-bold text-[#63C7AA]'>{t('product.engage.title')}</h1>
                    {/*  Section description */}

                    {/* Section key message */}
                    <h2 className='widget-keyMessage-title'>{t('product.engage.keyMessage')}</h2>

                    {/* Section description */}
                    <p className='widget-keyMessage-description'>{t('product.engage.keyMessage.description')}</p>

                    {/* Section key bullet points */}
                    <div className='flex flex-col items-start text-left space-y-4 justify-between w-full pt-4'>
                        <div className='widget-bulletPoints'><span className='mr-4'>📈</span>{t('product.engage.bullet1')}</div>
                        <div className='widget-bulletPoints'><span className='mr-4'>🤝</span>{t('product.engage.bullet2')}</div>
                        <div className='widget-bulletPoints'><span className='mr-4'>🌟</span>{t('product.engage.bullet3')}</div>
                    </div>


                </div>

                {/* Image right side */}
                <div className='flex flex-col items-center justify-center w-full lg:w-2/3 pt-8'>
                    <div className='container content-fit mx-auto lg:w-2/3 max-w-sm lg:max-w-xl overflow-hidden'>
                        <img className='rounded-lg object-cover w-full h-auto shadow rounded-lg' 
                            alt="Kincode App - engagement platform " 
                            src="/assets/illustrations/enagement-illustration.webp" />
                    </div>
                </div>


            </div>

            {/* Customers */}
            <CustomersWidget />

            {/* Pulse */}
            <TextAndImage 
                title={t('home.pricing.feature.pulse')}
                keyMessage={t('product.pulse.keyMessage')}
                keyMessageDescription={t('product.pulse.keyMessage.description')}
                bulletPoint1={t('product.pulse.bullet1')} bulletPoint1Emoji="📊"
                bulletPoint2={t('product.pulse.bullet2')} bulletPoint2Emoji="🔧"
                bulletPoint3={t('product.pulse.bullet3')} bulletPoint3Emoji="⏰"
                img="/assets/illustrations/dashboards-illustration.webp"
                alt="Kincode App - engagement platform" 
                urlLearnMore={'/product/engage/pulse/'}
                orientation="left"
            />

            {/* Testimonials  */}
            <Testimonials />

            {/* 1:1 meetings */}
            <TextAndImage
                title={t('home.pricing.feature.1-1s')}
                keyMessage={t('product.1-1s.keyMessage')}
                keyMessageDescription={t('product.1-1s.keyMessage.description')}
                bulletPoint1={t('product.1-1s.bullet1')} bulletPoint1Emoji="🎯"
                bulletPoint2={t('product.1-1s.bullet2')} bulletPoint2Emoji="🛠️"
                bulletPoint3={t('product.1-1s.bullet3')} bulletPoint3Emoji="⏰"
                img="/assets/illustrations/meetings-ilustration.webp"
                alt="Kincode App - engagement platform" 
                urlLearnMore={'/product/engage/meetings/'}
                orientation="right"
            />

            {/* Recognition */}
            <TextAndImage
                title={t('home.pricing.feature.recognition')}
                keyMessage={t('product.recognition.keyMessage')}
                keyMessageDescription={t('product.recognition.keyMessage.description')}
                bulletPoint1={t('product.recognition.bullet1')} bulletPoint1Emoji="🌟"
                bulletPoint2={t('product.recognition.bullet2')} bulletPoint2Emoji="🏆"
                bulletPoint3={t('product.recognition.bullet3')} bulletPoint3Emoji="🎁"
                img="/assets/illustrations/recognition-ilustration.webp"
                alt="Kincode App - engagement platform"
                urlLearnMore={'/product/engage/recognition/'}
                orientation="left"
            />

            {/* Book a demo - or free trial CTA */}
            <CTA />

        </div>
        
        
        <Footer/>

      </React.Fragment>
  );

}

export default Engage;