// React
import React from "react";
import { useNavigate} from "react-router-dom";

// 3P
import ReactGA from 'react-ga4';

// Icons
import { IoPersonAdd, IoPulseSharp } from "react-icons/io5";
import { BsPersonBadgeFill, BsFillInboxFill} from "react-icons/bs";
import {FaBalanceScale } from "react-icons/fa";
import {CgPerformance} from "react-icons/cg";
import {SiCrowdsource} from "react-icons/si";
import {GiArcheryTarget, GiPalmTree, GiOrganigram} from 'react-icons/gi';
import {FaPeopleArrows, FaAward} from 'react-icons/fa';
import {PiPlantBold} from 'react-icons/pi';
import {BsLadder} from 'react-icons/bs';

// Sections within Landing Page
import { useTranslation } from "react-i18next";

function PricingWidget() {
// ------------------------------------------------------------------------------------------

  const {t} = useTranslation();
  const navigate = useNavigate();

//------------------------------------------------------------------------------------------
// Frontend functions
//------------------------------------------------------------------------------------------
    async function handleDemoButton (e) {
      // Avoid button default behavior - refresh page
      e.preventDefault();

      // Log GA4 event
      ReactGA.event({
        category: "conversion",
        action: `Quote button clicked: ${e.target.id}`,
        label: "Pricing widget - quote button clicked", // optional
      });
  
      // Navigate to contact page
      navigate("/contact");
    }

// ------------------------------------------------------------------------------------------
// Rendering functions
// ------------------------------------------------------------------------------------------
    return (
        <React.Fragment>
            <div className="flex flex-col p-4 ">
                        <h2 className="text-center text-xl lg:text-3xl font-black text-[#FFBF76]"> {t('home.pricing.title')}</h2>
                        <span className="mt-4 text-sm text-gray-800"> {t('home.pricing.description')} </span>

                        <span className="mt-4 text-sm text-gray-800"> {t('home.pricing.pepm-description')} </span>
                      </div>

                      <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center lg:space-x-8 ">
                        {/* Card 1 */}
                        <div className="flex flex-col items-center justify-center bg-white rounded-xl border-2 border-violet-200 shadow-lg lg:w-1/4 p-8 m-2">
                            {/* Card title */}
                            <div className="flex flex-row items-start justify-between w-full">
                              <h3 className="text-lg font-bold text-left p-2 w-1/2"> {t('home.pricing.tier-1.title')}</h3>
                              <div className="flex flex-col p-4 items-center bg-gray-100 rounded-xl ">
                                <span className="flex flex-row text-2xl font-bold m-2"> {t('home.pricing.tier-1.price')} €</span>
                                <span className="flex text-gray-600">{t('home.pricing.units')}</span>
                              </div>
                            </div>

                            {/* Divider */}
                            <div className="border-b-gray-200 border-b w-full mt-8 mb-8" />
                            {/* Card features */}
                            <div className="flex flex-col items-start text-left space-y-4 text-sm text-gray-800">
                                <div className="flex flex-col space-y-4 ">
                                    <span className="flex flex-row font-bold">
                                        <IoPulseSharp className="flex text-lg mr-2"/> {t('home.pricing.feature.pulse')}  
                                    </span>
                                    <ul className="list-disc ml-12 mt-4 space-y-4">
                                        <li>{t('home.pricing.feature.pulse.bullet-1')}</li>
                                        <li>{t('home.pricing.feature.pulse.bullet-2')}</li>
                                        <li>{t('home.pricing.feature.pulse.bullet-3')}</li>
                                        <li>{t('home.pricing.feature.pulse.bullet-4')}</li>
                                        <li>{t('home.pricing.feature.pulse.bullet-5')}</li>
                                    </ul>
                                </div>
                                <div className="flex flex-col space-y-4 ">
                                    <span className="flex flex-row font-bold">
                                        <FaPeopleArrows className="flex text-lg mr-2"/> {t('home.pricing.feature.1-1s')} 
                                    </span>
                                    <ul className="list-disc ml-12 mt-4 space-y-4">
                                        <li>{t('home.pricing.feature.1-1s.bullet-1')}</li>
                                        <li>{t('home.pricing.feature.1-1s.bullet-2')}</li>
                                        <li>{t('home.pricing.feature.1-1s.bullet-3')}</li>
                                    </ul>

                                </div>
                                <div className="flex flex-col space-y-4 ">
                                    <span className="flex flex-row font-bold">
                                        <FaAward className="flex text-lg mr-2"/> {t('home.pricing.feature.recognition')}
                                    </span>
                                    <ul className="list-disc ml-12 mt-4 space-y-4">
                                        <li>{t('home.pricing.feature.recognition.bullet-1')} </li>
                                        <li>{t('home.pricing.feature.recognition.bullet-2')}</li>
                                    </ul>

                                </div>

                              
                            </div>
                            {/* CTA */}
                            <div className="flex flex-col items-center justify-center mt-8">
                              <button id="price-engage-cta-button" className="flex items-center justify-center px-8 py-4 text-sm font-bold text-black bg-[#FFBF76] rounded-xl"
                                                            onClick={(e) => handleDemoButton(e)}>
                                {t('home.pricing.cta')}
                              </button>
                            </div>

                        </div>
                        {/* Card 2 */}
                        <div className="flex flex-col items-center justify-center bg-white rounded-xl 
                        border-2 border-indigo-200 shadow-lg lg:w-1/4 p-8 m-2">
                            {/* Card title */}
                            <div className="flex flex-row items-start justify-between w-full">
                              <h3 className="text-lg font-bold text-left p-2 w-1/2"> {t('home.pricing.tier-2.title')}</h3>
                              <div className="flex flex-col p-4 items-center bg-gray-100 rounded-xl ">
                                <span className="flex flex-row text-2xl font-bold m-2"> {t('home.pricing.tier-2.price')} €</span>
                                <span className="flex text-gray-600">{t('home.pricing.units')}</span>
                              </div>
                            </div>
                            {/* Divider */}
                            <div className="border-b-gray-200 border-b w-full mt-8 mb-8" />
                            {/* Card features */}
                            <div className="flex flex-col items-start text-left space-y-4 text-sm text-gray-800">
                                <div className="flex flex-col space-y-4 ">
                                    <span className="flex flex-row font-bold">
                                        <CgPerformance className="flex mr-2 text-lg"/> {t('home.pricing.feature.performance')}  
                                    </span>
                                    <ul className="list-disc ml-12 mt-4 space-y-4">
                                        <li> {t('home.pricing.feature.performance.bullet-1')} </li>
                                        <li> {t('home.pricing.feature.performance.bullet-2')} </li>
                                        <li> {t('home.pricing.feature.performance.bullet-3')} </li>
                                        <li> {t('home.pricing.feature.performance.bullet-4')} </li>
                                        <li> {t('home.pricing.feature.performance.bullet-5')} </li>
                                    </ul>
                                </div>
                                <div className="flex flex-col space-y-4 ">
                                    <span className="flex flex-row font-bold">
                                        <FaBalanceScale className="flex  text-lg mr-2"/> {t('home.pricing.feature.calibration')}
                                    </span>
                                    <ul className="list-disc ml-12 mt-4 space-y-4">
                                        <li> {t('home.pricing.feature.calibration.bullet-1')} </li>
                                        <li> {t('home.pricing.feature.calibration.bullet-2')} </li>
                                        <li> {t('home.pricing.feature.calibration.bullet-3')} </li>
                                    </ul>

                                </div>
                                <div className="flex flex-col space-y-4 ">
                                    <span className="flex flex-row font-bold">
                                        <GiArcheryTarget className="flex text-lg text-lg  mr-2"/> {t('home.pricing.feature.goals')}
                                    </span>
                                    <ul className="list-disc ml-12 mt-4 space-y-4">
                                        <li>{t('home.pricing.feature.goals.bullet-1')}</li>
                                        <li>{t('home.pricing.feature.goals.bullet-2')}</li>
                                        <li>{t('home.pricing.feature.goals.bullet-3')}</li>
                                    </ul>
                                </div>
                            </div>
                            {/* CTA */}
                            <div className="flex flex-col items-center justify-center mt-8">
                              <button id="price-perform-cta-button" className="flex items-center justify-center px-8 py-4 text-sm font-bold text-black bg-[#FFBF76] rounded-xl"
                              onClick={(e) => handleDemoButton(e)}>
                                {t('home.pricing.cta')}
                              </button>
                            </div>

                        </div>
                          
                        {/* Card 3 */}
                        <div className="flex flex-col items-center justify-center bg-white rounded-xl border-2 border-green-200 shadow-lg lg:w-1/4 p-8 m-2">
                            {/* Card title */}
                            <div className="flex flex-row items-start justify-between w-full">
                              <h3 className="text-lg font-bold text-left p-2 w-1/2"> {t('home.pricing.tier-3.title')}</h3>
                              <div className="flex flex-col p-4 items-center bg-gray-100 rounded-xl ">
                                <span className="flex flex-row text-2xl font-bold m-2"> {t('home.pricing.tier-3.price')} €</span>
                                <span className="flex text-gray-600">{t('home.pricing.units')}</span>
                              </div>
                            </div>
                            {/* Divider */}
                            <div className="border-b-gray-200 border-b w-full mt-8 mb-8" />
                            {/* Card features */}
                            <div className="flex flex-col items-start text-left space-y-4 text-sm text-gray-800">
                                <div className="flex flex-col space-y-4 ">
                                    <span className="flex flex-row font-bold">
                                        <SiCrowdsource className="flex mr-2 text-lg"/> {t('home.pricing.feature.recruitment')}
                                    </span>
                                    <ul className="list-disc ml-12 mt-4 space-y-4">
                                        <li> {t('home.pricing.feature.recruitment.bullet-1')} </li>
                                        <li> {t('home.pricing.feature.recruitment.bullet-2')} </li>
                                        <li> {t('home.pricing.feature.recruitment.bullet-3')} </li>
                                        <li> {t('home.pricing.feature.recruitment.bullet-4')} </li>
                                    </ul>
                                </div>
                                <div className="flex flex-col space-y-4 ">
                                    <span className="flex flex-row font-bold">
                                        <IoPersonAdd className="flex text-lg mr-2"/> {t('home.pricing.feature.onboarding')}
                                    </span>
                                    <ul className="list-disc ml-12 mt-4 space-y-4">
                                        <li> {t('home.pricing.feature.onboarding.bullet-1')} </li>
                                        <li> {t('home.pricing.feature.onboarding.bullet-2')} </li>
                                    </ul>
                                </div>
                                <div className="flex flex-col space-y-4 ">
                                    <span className="flex flex-row font-bold">
                                        <PiPlantBold className="flex text-lg mr-2"/> {t('home.pricing.feature.development')}
                                    </span>
                                    <ul className="list-disc ml-12 mt-4 space-y-4">
                                        <li> {t('home.pricing.feature.development.bullet-1')} </li>
                                        <li> {t('home.pricing.feature.development.bullet-2')} </li>
                                    </ul>
                                </div>
                                <div className="flex flex-col space-y-4 ">
                                    <span className="flex flex-row font-bold">
                                        <BsLadder className="flex text-lg mr-2"/> {t('home.pricing.feature.career')}
                                    </span>
                                    <ul className="list-disc ml-12 mt-4 space-y-4">
                                        <li> {t('home.pricing.feature.career.bullet-1')} </li>
                                        <li> {t('home.pricing.feature.career.bullet-2')} </li>
                                        <li> {t('home.pricing.feature.career.bullet-3')} </li>
                                    </ul>
                                </div>

                              
                            </div>
                            {/* CTA */}
                            <div className="flex flex-col items-center justify-center mt-8">
                              <button id="price-develop-cta-button" className="flex items-center justify-center px-8 py-4 text-sm font-bold text-black bg-[#FFBF76] rounded-xl"
                                    onClick={(e) => handleDemoButton(e)}>
                                {t('home.pricing.cta')}
                              </button>
                            </div>

                        </div>
                </div>

            <div className="flex flex-col lg:flex-row justify-center ">
                <div className="flex flex-col items-center justify-center 
                bg-white rounded-xl border-2 border-green-600 shadow-lg lg:w-3/4 p-8 m-2">
                        {/* Card title */}
                        <div className="flex flex-row items-center justify-between w-full">
                            <div className="flex flex-col lg:flex-row items-start lg:items-center">
                                <h3 className="lg:text-lg font-bold text-left lg:p-2"> {t('home.pricing.tier-1.title')}</h3>
                                +
                                <h3 className="lg:text-lg font-bold text-left lg:p-2"> {t('home.pricing.tier-2.title')}</h3>
                                +
                                <h3 className="lg:text-lg font-bold text-left lg:p-2"> {t('home.pricing.tier-3.title')}</h3>
                                
                            </div>
                            <div className="flex flex-col p-4 items-center bg-gray-100 rounded-xl ">
                                    <span className="flex flex-row text-2xl font-bold m-2"> {t('home.pricing.tier-all.price')} €</span>
                                    <span className="flex text-gray-600">{t('home.pricing.units')}</span>
                            </div>
                        </div>

                        <div className="flex flex-row text-left mt-4 mb-4 w-full">
                            <span className="text-left text-sm text-gray-800"> 
                                {t('home.pricing.tier-all.description')} 
                            </span>
                        </div>

                            
                        {/* CTA */}
                        <div className="flex flex-col items-center justify-center mt-8">
                            <button id="price-all-cta-button" className="flex items-center justify-center px-8 py-4 text-sm font-bold text-black bg-[#FFBF76] rounded-xl"
                            onClick={(e) => handleDemoButton(e) } >
                            {t('home.pricing.cta')}
                            </button>
                        </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default PricingWidget;
