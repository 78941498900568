// React
import React from 'react';

// i18n
import { useTranslation } from 'react-i18next';

import {customerList} from '../data/customerList';

// ------------------------------------------------------------------------------------------
// Widget
// ------------------------------------------------------------------------------------------
const CustomersWidget = () => {
    const {t} = useTranslation();

    // ------------------------------------------------------------------------------------------
    // Rendering
    // ------------------------------------------------------------------------------------------
    return (
        <div className='widget-container '>
            <h2 className='flex flex-row items-center justify-center font-mono mb-8 text-base lg:text-lg text-gray-600'>{t('widgets.customers.title')}</h2>
            <div className="flex flex-row items-center justify-center space-x-4 lg:space-x-20">
                    {customerList.map((customer, index) => (
                        <a
                            key={index}
                            href={customer.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="grayscale"
                        >
                            <img src={customer.logoURL} alt={customer.alt} />
                        </a>
                    ))}
            </div>
        </div>
    );
};

export default CustomersWidget;

