// React
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

// Common
import Header from '../common/newHeader';
import Footer from '../common/footer';

// 3P
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

function Product () {
  const location = useLocation();
  const {t} = useTranslation();


// ------------------------------------------------------------------------------------------
// Functions
// ------------------------------------------------------------------------------------------
useEffect (() => {
  // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Products" });
  
  }, []);


  // ------------------------------------------------------------------------------------------
  // Render function
  // ------------------------------------------------------------------------------------------
  return(
      <React.Fragment>
        <Header/>

        
        
        <Footer/>

      </React.Fragment>
  );

}

export default Product;