// React
import React, {useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";

// 3P
import ReactGA from 'react-ga4';
import { useTranslation } from "react-i18next";

// Header & Footer
import Header from '../common/newHeader';
import Footer from '../common/footer';
import Bot from '../common/bot';
import Customers from '../widgets/customers';
import CTA from "../widgets/cta";

// data
import { teamList } from "../data/teamList";
import { companyValues } from "../data/companyValues";



function About () {

  // Variables
  const {t} = useTranslation();
  const location = useLocation();

  // ------------------------------------------------------------------------------------------
  // useEffect
  // ------------------------------------------------------------------------------------------
  useEffect (() => {
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "About" });
            
        // Scroll to top
        window.scrollTo(0, 0);
  }, []);



  // ------------------------------------------------------------------------------------------
  // Rendering functions
  // ------------------------------------------------------------------------------------------
  return (
    
      <React.Fragment>
          <Header />

          <div className="flex flex-col mt-20 mb-20">

            {/* KeyMessage */}
            <div className="widget-container ">
              <h1 className="flex flex-col items-center text-center w-full items-center justify-start text-left text-2xl lg:text-4xl font-black">{t('about.keyMessage')}</h1>
            </div>

            {/* Mission */}
            <div className="widget-container bg-green-50">
              {/* Description */}
              <h2 className="flex flex-row w-full items-center justify-start text-left text-2xl lg:text-4xl font-black">{t('about.mission')}</h2>
              <p className="flex flex-row w-full items-center justify-start text-justify mt-4 mb-4 text-base lg:text-lg text-gray-600 font-base">
                {t('about.mission.description')}
              </p>

              {/*  Image center wide */}
              <div className="flex flex-row w-full items-center justify-center pt-8">
                  <img
                    className="rounded-lg h-96"
                    src="/assets/images/kincode-mission.webp"
                    alt="Mission"
                  />
              </div>
            </div>

            {/* Vision */}
            <div className="widget-container">
              <h2 className="flex flex-row w-full items-center justify-start text-left text-2xl lg:text-4xl font-black">{t('about.vision')}</h2>
              <p className="flex flex-row w-full items-center justify-start text-justify mt-4 mb-4 text-base lg:text-lg text-gray-600 font-base">
                {t('about.vision.description')}
              </p>
            </div>


            {/* Other companies who care about culture */}
            {/* <div className="bg-gray-50">
              <Customers />
            </div> */}

            {/* Values */}
            {/* <div className="widget-container">
              <h2 className="flex flex-row w-full items-center justify-start text-left text-2xl lg:text-4xl font-black">{t('about.values')}</h2>

              {/* Values */}
              {/* <div className="flex flex-wrap items-center justify-start w-full p-4">
                {companyValues?.map((value, index) => (
                  <div key={index} className="flex flex-col items-center justify-center w-1/2 md:w-1/4 p-4">
                    <img
                      className="rounded-full h-32 w-32"
                      src={value.imageURL}
                      alt={value.alt}
                    />
                    <div className="text-center mt-4">
                      <h1 className="text-lg font-bold">{value.name}</h1>
                      <p className="text-xs lg:text-sm">{value.description}</p>
                    </div>
                  </div>
                ))}

              </div>

            </div> */}


            {/* Team */}
            {/* <div className="widget-container">
            <h2 className="flex flex-row w-full items-start justify-start text-left text-2xl lg:text-4xl font-black">{t('about.people')}</h2>

              <div className="flex flex-wrap items-start">
              {teamList?.map((teamMember, index) => (
                <div key={index} className="flex flex-col items-center justify-center w-1/2 md:w-1/4 p-4">
                  <img
                    className="rounded-full h-32 w-32"
                    src={teamMember.imageURL}
                    alt={teamMember.alt}
                  />
                  <div className="text-center mt-4">
                    <h1 className="text-lg font-bold">{teamMember.name}</h1>
                    <p className="text-gray-600 text-xs lg:text-sm">{teamMember.description}</p>
                  </div>
                </div>
              ))}
              </div>
            </div> */}
          </div>

          <CTA />


        {/* 
            <div className="text-justify">
              <span className="text-sm">The workplace is evolving. Employees are demanding more from their companies, not only more salary. They are demanding companies with a strong identity, defined by the company culture. </span>
              <br></br>
              <span className='text-sm'>On the other hand, employers are struggling to define and implement a strong culture, which would enable them to find the right people.</span>
              <br></br>
              <span className='text-sm'>Our mission is to provide the right tools for companies to (i) define their unique company culture, and (ii) successfully implement it among employees.</span>

           
            </div>
            <div className="text-justify">
            <span className="text-sm">We think of Kincode as the convergence of family and values. <b>Kin</b> is defined as <b>"one's family and relations"</b>. <b>Code</b> is defined as <b>"a set of conventions or moral principles governing behaviour in a particular sphere."</b></span>
            </div>
        
          </div>
        </div> */}


    <Footer />

    </React.Fragment>
  );

}


export default About;