
// React 
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

// 3P
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

// Common
import Footer from '../common/footer';
import Header from '../common/newHeader';
import SubscribeForm from '../common/subscribeForm';


function Contact () {
    // ------------------------------------------------------------------------------------------
    // State variables
    const location = useLocation();
    const {t} = useTranslation();


    // ------------------------------------------------------------------------------------------
    // Functions
    // ------------------------------------------------------------------------------------------
    useEffect (() => {
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "ContactUs" });

        // HubSpot form script
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    region: 'eu1',
                    portalId: '144414168',
                    formId: '8943f04d-9246-48df-b89c-8654fe3a16ff',
                    target: '#hubspotForm'
                })
            }
        });

        // Scroll to top unless there is an #target in the URL then scroll to target
        if (location.hash) {
            const element = document.querySelector(location.hash);
            element.scrollIntoView({ behavior: "smooth" });
        } else {
            window.scrollTo(0, 0);
        }
        
      }, []);
    
    
    
    // ------------------------------------------------------------------------------------------
    // Rendering functions
    // ------------------------------------------------------------------------------------------
    return(
    <React.Fragment>
        <Header />

        <div className='flex lg:flex-row flex-col items-center justify-center lg:m-52 mt-32 rounded-xl shadow-lg lg:border lg:border-gray-100'>
            {/* DESKTOP - Contact form image */}
            <div className='relative  flex flex-col items-start bg-[#BFC6FF] lg:w-1/2  rounded-xl mb-20 lg:mb-0 
                            lg:block hidden'>
                <div className='absolute p-8 text-white text-left mt-12 '>
                    <h1 className='text-2xl lg:text-4xl font-bold lg:mb-4'>
                        {t('contact.title')}
                    </h1>
                    <span className=''>
                        {t('contact.description')}
                    </span>
                </div>
                <img
                    src="/assets/images/contact-us-background.png"    
                    atl="Contact us"
                    className='w-full rounded-xl '
                    />
            </div>
            {/* MOBILE - Contact form image */}
            <div className='relative  flex flex-col items-start bg-[#BFC6FF] lg:w-1/2 rounded-xl mb-12 lg:mb-0 w-full
                            lg:hidden'>
                <div className='absolute p-8 text-white text-left mt-4'>
                    <h1 className='text-2xl lg:text-4xl font-bold lg:mb-4'>
                        {t('contact.title')}
                    </h1>
                    <span className=''>
                        {t('contact.description')}
                    </span>
                </div>
                <div className='w-full h-40 max-h-40 overflow-hidden rounded-xl'>
                <img
                    src="/assets/images/contact-us-background.png"    
                    atl="Contact us"
                    className='rounded-xl object-fill w-full'
                    />
                </div>
            </div>

            {/* Form */}
            <div className='flex flex-col items-center lg:border-l p-4 lg:p-8 lg:w-1/2 w-full'>
                {/* <SubscribeForm buttonTitle={t('cta')} /> */}
                {/* Contact form */}
                    <div id='hubspotForm' className='w-full'></div>
            </div>

        </div>

        <div className='flex flex-col items-center justify-center w-full space-y-12 mt-12 lg:mt-0'>
            <span className='text-lg lg:text-2xl font-black' id="book-a-meeting" title="book-a-meeting">
                {t('contact.meeting.title')}
            </span>     

        {/* Meeting */}
            <iframe
                    title={'hubspot'}
                    className='w-full h-screen'
                    src={'https://meetings-eu1.hubspot.com/miguel-alvarez?embed=true'}
                    />
        </div>


        <Footer />

    </React.Fragment>
);

}

export default Contact;