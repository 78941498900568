// React
import React from 'react';
import { Link } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

const CTA = () => {
    const {t} = useTranslation();

    // ------------------------------------------------------------------------------------------
    // Render function
    // ------------------------------------------------------------------------------------------
    return (
        <div className="widget-container bg-green-200">
            <div className='flex flex-col items-center space-y-8'>
                <div className='flex flex-col items-center space-y-2'>
                    <h2 className="widget-keyMessage-title">{t('widgets.cta.title')}</h2>
                    <p className="widget-keyMessage-description">{t('widgets.cta.description')}</p>
                </div>
                <Link to="/contact/" >
                    <button className="cta-button">
                    {t('widgets.cta.button')}
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default CTA;
