// React
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

// Common
import Header from '../common/newHeader';
import Footer from '../common/footer';

// 3P
import ReactGA from 'react-ga4';
import { useTranslation } from "react-i18next";

// ------------------------------------------------------------------------------------------
// Functions
// ------------------------------------------------------------------------------------------
function Security () {
    // Variables
    const location = useLocation();
    const {t} = useTranslation();



// ------------------------------------------------------------------------------------------
// After rendering method
// ------------------------------------------------------------------------------------------
    useEffect (() => {
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Security" });
                
        // Scroll to top
        window.scrollTo(0, 0);
        }, []);

// ------------------------------------------------------------------------------------------
// Render function
// ------------------------------------------------------------------------------------------
        return (
            <React.Fragment>
                <Header/>

                <div className='flex flex-col lg:m-52 mt-32 m-4'>

                <h1 className='font-bold text-2xl lg:text-4xl mt-8 mb-8 text-left'> How do we protect your data? </h1>
                <p className='text-justify'>
                    We are a service that operates on the internet (cloud service), and keeping your data safe is very important to us. 
                    Below, we've listed some steps we take to ensure your data stays private. We also have many other safety steps in place, but they are either too complex to explain easily, or it's not smart to share them publicly for extra safety. 
                    If you have any questions, feel free to reach out to us!
                </p>    

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> 🎖️ ISO 27001 risk mitigation </h2>
                <p className='text-justify'>
                    Kincode is currently pursuing compliance by ISO/IEC 27001:2013, which is globally recognized as the leading information security management system (ISMS) standard.
                </p>
     

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> ☁️ Hosting </h2>
                <p className='text-justify'>
                Our application is hosted on servers provided by Amazon Web Services to Heroku in its European data centers. 
                 Amazon Web Services is a leading “platform as a service” provider. 
                It provides best-in-class security infrastructure taking care of backups, logging, auditing, and other related services.

                <a href="https://aws.amazon.com/es/compliance/programs/" target="blank" className='underline'>Amazon Web Services</a>  is constantly auditing and is compliant with the following standards, among others:
                <ul className='list-disc m-4'>
                    <li className='ml-4'>ISO 27001</li>
                    <li className='ml-4'>ISO 27018</li>
                    <li className='ml-4'>SOC 2</li>
                    <li className='ml-4'>SOC 3</li>
                </ul>


                Other service providers we use at Kincode to provision our service include similarly renowned and certified companies such as:

                <ul className='list-disc m-4'>
                    <li className='ml-4' href="https://www.heroku.com/compliance" target="blank">Heroku</li>
                    <li className='ml-4'>MongoDB</li>
                    <li className='ml-4'>Microsoft</li>
                    <li className='ml-4'>GutHub</li>
                    <li className='ml-4'>OpenAI</li>
                </ul>
                Any transfer of data to a state that is not a member state of either the European Union or the European Economic Area will only occur in compliance with the GDPR and if the specific requirements of Article 44 et seq. of the General Data Protection Regulation (GDPR) have been fulfilled. 
                Specifically, a transfer requires a clear contractual agreement between Kincode and any subcontractor that guarantees at least the same level of data protection under standard contractual clauses (SCCs) as stipulated by the European Commission.
                </p>

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> *️⃣ Passwords </h2>
                <p className='text-justify'>
                    Your passwords are always encrypted (hashed, with salts) and never saved in plain text. When a user tries to log in, their password is encrypted in the same way, and the platform compares the encrypted versions to check if they match. 
                    This also means that we cannot recover a password for you (we only hold the encrypted version), and you have to reset your password if you lose it. 
                    
                    You can also Sign in with your Company's identity provider, such as: Google, or Microsoft Active Directory, or other SSO providers via a secure connection. 
                    In that case, your passwords are not stored on our servers at all. 
                    Instead, your users are redirected to a page where they authenticate Kincode as a trusted service, and a token is generated (which we can use to identify your users). 
                </p>

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> 🍪 Cookies and Tokens</h2>
                <p className='text-justify'>
                Our platform uses cookies and tokens to authenticate users across sessions. Tokens never contain your actual password or other sensitive information. All that gets saved is a randomly created token that allows you to access basic functionality. To access critical functionality — like changing your password — you have to re-enter your password.
                </p>

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> 🔒 Data Encryption </h2>
                <p className='text-justify'>
                    All communication between your users and our servers is SSL-encrypted. SSL (Secure Sockets Layer) is the standard security technology for establishing an encrypted link between a web server and a browser. This link ensures that all data passed between the web server and browsers remain private and integral. 
                    SSL is an industry standard and is used by millions of websites in the protection of their online transactions with their customers.
                </p>
                <p className='text-justify'>
                    All our data is encrypted at rest in <a href="https://www.mongodb.com/docs/atlas/setup-cluster-security/#encryption-at-rest" target="blank" className='underline'>MongoDB</a> .
                </p>


                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> 🛠️ Secure Frameworks</h2>
                <p className='text-justify'>
                    In addition to a secure hosting environment, we’re building on established software libraries to guarantee that your data is secure and your users are not exposed to vulnerabilities.
                    Our frontend framework React.js, combined with the use of unique user tokens, protects your users against common threats such as cross-site scripting (CSS / XSS) and cross-site request forgery (CSRF / XSRF).
                    We're using MongoDB as a data store, meaning that our application is not vulnerable to SQL injections. The use of an established middleware and input sanitation of all input adds further protection.
                    As mentioned above, our application runs on AWS servers. AWS keeps the server software up to date at all times and fixes any new security vulnerabilities immediately.
                </p>

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> 🛡️ Preventing access from within</h2>
                <p className='text-justify'>
                    Even an authenticated (logged in) user may try to exploit vulnerabilities — someone could, for example, register for a demo account and try to access other clients’ information.
                    While the software frameworks listed above already protect the system from that threat, the application code additionally checks each request and verifies that the database object’s company ID matches the company ID of the user. 
                    Each database object is tagged with a company ID, and any potential attempts to breach those trigger immediate notifications to our administrators.
                    We also apply a strict role-based model to all requests and views of the platform. This prevents employees from accessing functionality (like modifying user data, editing billing information, etc.) that should be reserved to administrators only.
                </p>

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> 🚫 Access restrictions to code and database </h2>
                <p className='text-justify'>
                    Our application and database are hosted in a securely guarded data center where professional staff takes care of the physical security of servers.
                    Remote access is strictly limited, too. Within our team, each deployment of new code has to be approved by one of two people that have access. 
                    The same access limitation applies to our databases and internal administration area. 
                    Access to the databases, our central code repository, and our hosting environment is furthermore protected by two-factor-authentication. 
                    We regularly update passwords and security tokens.
                    In our internal administration data, we only display aggregated statistics and company-level data (such as invoicing information), not the content of actual feedback, reviews, etc. 
                    We do not look into raw customer data unless we have been granted permission to do so to fix a bug. That said, most bugs can be fixed by analyzing server logs and reproducing the problem with dummy data.
                </p>

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> 📄 Data processing agreement (DPA) </h2>
                <p className='text-justify'>
                    Once you start using Kincode, you will sign a data processing agreement with us. 
                    It lays out how we may handle your data, explains the security measures deployed, states your rights, and is needed to be fully compliant with the GDPR.
                </p>

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> 🔐 Internal security policies </h2>
                <p className='text-justify'>
                    Our team is highly security-aware. 
                    To avoid falling prey to outside tricksters, we regularly hold internal security briefings, only deploy up-to-date and modern browsers, use password managers and different passwords for all sites, regularly update passwords, and encrypt the hard drives of our devices.
                </p>

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> 📶 Availability and disaster recovery </h2> 
                <p className='text-justify'>
                    Our application and databases are distributed and replicated across various servers. 
                    In the event that one of those servers goes down, another instance would take over the job of serving the application, usually without the end-user actually noticing.
                    Databases are backed up on a continuous basis and can be restored should the software or server ever fail in a significant way. 
                    Backups are stored in different availability zones for additional security.
                </p>

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> 📈 Monitoring</h2>
                <p className='text-justify'>
                    We closely monitor the performance of our application and databases via Heroku's and MongoDB monitoring tools. 
                    Any internal errors or potential failures of our various integrations are logged and trigger notifications to our development team, usually allowing us to identify the problem within a few minutes and swiftly remedy the situation.
                </p>

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> 📬 User requests and bug reports</h2>
                <p className='text-justify'>
                Having said the above, sometimes it’s users who notice a glitch or stumble across a bug in the software. 
                We encourage you to get in touch via our support form (accessible via the button in the bottom-right corner of the screen) or email at miguel@kincode.app — we greatly appreciate any hints or feedback. 
                We aim to deal with critical requests within 4 hours.
                </p>

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> 👾 Found a security threat?</h2>
                <p className='text-justify'>
                    We run regular external penetration tests/audits with industry-leading security specialists to detect potential vulnerabilities and protect your data.
                    If you think you have found a security threat in our system, please contact us immediately via miguel@kincode.app. 
                    Your information will remain confidential, and we will deal with your request immediately.
                </p>

                <h2 className='font-bold text-xl lg:text-3xl mt-8 mb-8 text-left'> 📢 Full-disclosure policy </h2>
                <p className='text-justify'>
                    If anything serious ever happens and your data is affected, we will provide full disclosure to enable you to take precautions and minimize the damage. 
                </p>
        </div>
        <Footer/>

    
    </React.Fragment>
    );
}

export default Security;