// React
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

// Common
import Header from '../../../common/newHeader';
import Footer from '../../../common/footer';

// 3P
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

// Widgets
import Testimonials from '../../../widgets/testimonials';
import CTA from '../../../widgets/cta';
import CustomersWidget from '../../../widgets/customers';
import TextAndImage from '../../../widgets/textAndimage';

function Organigram () {
  const location = useLocation();
  const {t} = useTranslation();


// ------------------------------------------------------------------------------------------
// Functions
// ------------------------------------------------------------------------------------------
useEffect (() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Product-Basics-Organigram" });

    // Scroll to top
    window.scrollTo(0, 0);
  
  }, []);


  // ------------------------------------------------------------------------------------------
  // Render function
  // ------------------------------------------------------------------------------------------
  return(
      <React.Fragment>
        <Header/>

        <div className='flex flex-col items-center h-auto w-auto'>
            {/* Headline */}
            <div className='flex flex-col space-y-12 lg:space-y-0 lg:flex-row items-center lg:justify-center w-full px-8 lg:px-40 py-24 bg-orange-50'>
                {/* Text left-side */}
                <div className='flex flex-col items-start w-full lg:w-1/2 space-y-4'>
                    {/* Section title */}
                    <h1 className='text-left text-lg font-bold text-[#63C7AA]'>{t('product.organigram.title')}</h1>
                    {/*  Section description */}

                    {/* Section key message */}
                    <h2 className='widget-keyMessage-title'>{t('product.organigram.keyMessage')}</h2>

                    {/* Section description */}
                    <p className='widget-keyMessage-description'>{t('product.organigram.keyMessage.description')}</p>

                    {/* Section key bullet points */}
                    <div className='flex flex-col items-start text-left space-y-4 justify-between w-full pt-4'>
                        <div className='widget-bulletPoints'><span className='mr-4'>🌳</span>{t('product.organigram.bullet1')}</div>
                        <div className='widget-bulletPoints'><span className='mr-4'>🤝</span>{t('product.organigram.bullet2')}</div>
                        <div className='widget-bulletPoints'><span className='mr-4'>🔍</span>{t('product.organigram.bullet3')}</div>
                    </div>


                </div>

                {/* Image right side */}
                <div className='flex flex-col items-center justify-center w-full lg:w-2/3 pt-8'>
                    <div className='container content-fit mx-auto lg:w-2/3 max-w-sm lg:max-w-xl overflow-hidden'>
                        <img className='rounded-lg object-cover w-full h-auto shadow rounded-lg' 
                            alt="Kincode App - engagement platform " 
                            src="/assets/illustrations/organigram/basics-organigram-illustration.webp" />
                    </div>
                </div>


            </div>

            {/* Customers */}
            <CustomersWidget />

            {/* Collaborative and transparent org */}
            <TextAndImage 
                            title={t('product.organigram.visibility.title')}
                            keyMessage={t('product.organigram.visibility.keyMessage')}
                            keyMessageDescription={t('product.organigram.visibility.keyMessage.description')}
                            bulletPoint1={t('product.organigram.visibility.bullet1')} bulletPoint1Emoji="🤝"
                            bulletPoint2={t('product.organigram.visibility.bullet2')} bulletPoint2Emoji="💡"
                            bulletPoint3={t('product.organigram.visibility.bullet3')} bulletPoint3Emoji="🔄"
                            img="/assets/illustrations/organigram/basics-organigram-openculture-illustration.webp"
                            alt="Kincode App - engagement platform"
                            // urlLearnMore={'/product/engage/organigram/'}
                            orientation="left"
                        />
            {/* Testimonials  */}
            <Testimonials />


            {/* Innovation metrics */}
            <TextAndImage 
                            title={t('product.organigram.innovation.title')}
                            keyMessage={t('product.organigram.innovation.keyMessage')}
                            keyMessageDescription={t('product.organigram.innovation.keyMessage.description')}
                            bulletPoint1={t('product.organigram.innovation.bullet1')} bulletPoint1Emoji="📊"
                            bulletPoint2={t('product.organigram.innovation.bullet2')} bulletPoint2Emoji="🏗️"
                            // bulletPoint3={t('product.organigram.innovation.bullet3')} bulletPoint3Emoji="💬"
                            img="/assets/illustrations/organigram/basics-organigram-innovation-illustration.webp"
                            alt="Kincode App - engagement platform" 
                            // urlLearnMore={'/product/engage/organigram/'}
                            orientation="right"
                        />





            {/* Book a demo - or free trial CTA */}
            <CTA />

        </div>
        
        
        <Footer/>

      </React.Fragment>
  );

}

export default Organigram;