// React
import React, { useEffect } from "react";
import {useLocation} from "react-router-dom";

// Header & Footer
import Header from '../common/newHeader';
import Footer from '../common/footer';
import PricingWidget from "../common/pricingWidget";

// 3P
import ReactGA from 'react-ga4';

// Sections within Landing Page
import { useTranslation } from "react-i18next";

function Pricing() {
// ------------------------------------------------------------------------------------------

  const {t} = useTranslation();
  const location = useLocation();

// ------------------------------------------------------------------------------------------
// Functions
// ------------------------------------------------------------------------------------------
useEffect (() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Pricing" });
    
    }, []);

// ------------------------------------------------------------------------------------------
// Rendering functions
// ------------------------------------------------------------------------------------------
    return (
        <React.Fragment>
            <Header />

            <div className="flex flex-col mt-40 mb-20">

                <PricingWidget />


                <div className="flex flex-col items-center mt-20">
                    Test

                </div>


            </div>

            <Footer/>
        </React.Fragment>
    )
}

export default Pricing;
