import React from 'react';
import {Link} from 'react-router-dom';
import {FaInstagram, FaLinkedinIn, FaMedium} from 'react-icons/fa';
import {FaXTwitter} from 'react-icons/fa6';
import {SiSubstack} from 'react-icons/si';
import { t } from 'i18next';

function Footer() {
    return (
        <nav className='flex flex-col bottom-0 mb-0 items-center pt-20 pb-20 text-sm bg-gray-100 '>
            
                    {/* KINCODE logo */}
                    <div className="flex flex-col items-center p-4">
                        <Link to="/" >
                            <div className="flex items-center container w-[75px] max-w-[75px]
                                            max-h-[20px] h-[20px]">
                                <img className="flex object-contain" alt="Kincode App logo"
                                    src ="https://www.dropbox.com/s/0q02lw1ryvovqva/Kincode-logo.png?raw=1"
                                />
                                <span className="font-mono m-4 text-2xl">Kincode</span>
                            </div>
                        </Link>
                    </div>

                    {/* Separator line */}
                    <div className='flex flex-row border-b border-black w-3/4 mt-8 mb-8' />

                    {/* Footer links */}
                    <div className='flex flex-wrap space-x-12 w-3/4 space-y-8 md:space-y-0'>
                        <div className='flex flex-col items-start text-left'>
                                <span className='text-sm font-bold'> {t('footer.platform')} </span>

                            {/* Subcolumns */}
                            <div className='flex flex-row space-x-8'>
                                {/* Column 1 */}
                                    <div className='flex flex-col items-start text-left mt-2 space-y-2'>
                                        {/* {/* <Link to="/products#Performance" > 
                                        {t('footer.platform.performance-review')}
                                        </Link>
                                        <Link to="/products#Calibration" > 
                                        {t('footer.platform.calibration')}
                                        </Link>
                                        <Link to="/products#1:1s" > 
                                        {t('footer.platform.1on1')}
                                        </Link>
                                        <Link to="/products#Performance" > 
                                            {t('footer.platform.recognition')}
                                        </Link>
                                        <Link to="/products#Performance" > 
                                            {t('footer.platform.pulse')}
                                        </Link> */}
                                        <Link to="/security" > 
                                        {t('footer.platform.security')}
                                        </Link>
                                        <Link to="/roadmap" > 
                                        {t('footer.platform.roadmap')}
                                        </Link>


                                    </div>
                                {/* Column 2 */}
                                    <div className='hidden flex flex-col items-start text-left mt-2 space-y-2'>
                                        <Link to="/products#Performance" > 
                                            {t('footer.platform.hr-inbox')}
                                        </Link>
                                        <Link to="/products#Calibration" > 
                                            {t('footer.platform.people-analytics')} 
                                        </Link>
                                        <Link to="/products#1:1s" > 
                                            {t('footer.platform.organigram')}
                                        </Link>
                                        <Link to="/products#Performance" >
                                            {t('footer.platform.employee-profile')}
                                        </Link>
                                        


                                    </div>
                            </div>
                        </div>
                        <div className='flex flex-col items-start'>
                                <span className='text-sm font-bold'> {t('footer.resources')} </span>
                            {/* Subcolumns */}
                            <div className=' flex flex-row space-x-8'>
                                {/* Column 1 */}
                                    <div className='flex flex-col items-start text-left mt-2 space-y-2'>
                                        <a href="https://resources.kincode.app" target="blank">
                                            {t('footer.resources.guide')}
                                        </a>
                                        
                                    </div>
                                
                            </div>
                        </div>


                        {/* <div className='flex flex-col items-center'>
                            <Link to="/products#Performance">
                                <span className='text-sm font-bold'>
                                    {t('footer.industry')}
                                </span>

                            </Link>
                        </div> */}
                        <div className='flex flex-col items-start text-left'>
                            <Link to="/partners">
                            <span className='hidden text-sm font-bold'>
                                {t('footer.why-kincode')}
                            </span>
                            <span className='text-sm font-bold'>
                                {t('footer.partners')}
                            </span>
                            {/* Subcolumns */}
                            <div className='flex flex-row space-x-8'>
                                {/* Column 1 */}
                                    <div className='flex flex-col items-start text-left mt-2 space-y-2'>
                                        
                                        <Link to="/partners" > 
                                            {t('footer.partners.become-a')}
                                        </Link>
                                        <Link to="/partners" > 
                                            {t('footer.partners.our-partners')}
                                        </Link>
                                        
                                    </div>
                                
                            </div>    

                            </Link>
                        </div>
                        <div className='flex flex-col items-start'>
                                <span className='text-sm font-bold'>
                                    {t('footer.company')}
                                </span>

                            {/* Subcolumns */}
                            <div className='flex flex-row space-x-8'>
                                {/* Column 1 */}
                                    <div className='flex flex-col items-start text-left mt-2 space-y-2'>
                                        <Link to="/about" > 
                                            {t('footer.company.about')}
                                        </Link>
                                        {/* <Link to="/products" > 
                                            {t('footer.company.products')}
                                        </Link> */}
                                        {/* <Link to="/solutions" > 
                                            {t('footer.company.solutions')}
                                        </Link> */}
                                        {/* <Link to="/pricing" > 
                                            {t('footer.company.pricing')}
                                        </Link> */}
                                        {/* <Link to="/contact" > 
                                            {t('footer.company.contact-us')}
                                        </Link> */}
                                        <Link to="/privacy" > 
                                            {t('footer.company.privacy-policy')}
                                        </Link>
                                        <Link to="/terms" > 
                                            {t('footer.company.terms-of-service')}
                                        </Link>
                                    </div>
                                
                            </div>

                        </div>
                        <div className='hidden flex flex-col items-start'>
                            <Link to="/products#Performance">
                                <span className='text-sm font-bold'>
                                    {t('footer.support')}
                                </span>
                            </Link>
                            {/* Subcolumns */}
                            <div className='flex flex-row space-x-8'>
                                {/* Column 1 */}
                                    <div className='flex flex-col items-start text-left mt-2 space-y-2'>
                                        <Link to="/products#Performance" > 
                                            {t('footer.support.help-center')}
                                        </Link>
                                        <Link to="/products#Calibration" > 
                                            {t('footer.support.university')}
                                        </Link>
                                    </div>
                                
                            </div>
                        </div>

                    </div>

                    {/* Separator line */}
                    <div className='flex flex-row border-b border-black w-3/4 mt-8 mb-8' />

                    {/* Social media links */}
                    <div className='flex flex-row w-3/4 justify-between'>
                        <div className='flex flex-col items-center'>
                            {t('footer.copyright')}
                        </div>

                        <div className='flex flex-row text-lg'>
                                <a href='https://www.linkedin.com/company/kincode-app/' target="_blank" rel="noreferrer" >
                                    <FaLinkedinIn className='mr-4' />
                                </a>
                                <a href='https://twitter.com/kincode_/' target="_blank" rel="noreferrer">
                                    <FaXTwitter className='mr-4'/>
                                </a>
                                <a href='https://www.instagram.com/kincode.app/' target="_blank" rel="noreferrer">
                                    <FaInstagram className='mr-4'/>
                                </a>
                                <a href='https://medium.com/@kincode.app' target="_blank" rel="noreferrer">
                                    <FaMedium className='mr-4' />
                                </a>
                                {/* <a href='https://kincode.substack.com' target="_blank" rel="noreferrer">
                                    <SiSubstack className='' />
                                </a> */}
                        </div>

                    </div>
                                        {/* <div className='flex flex-col w-1/2 lg:w-full md:flex-row items-center'>

                        <div className='flex flex-col items-center'>
                            <span className="flex text-sm m-2">Find us in our social: <br></br></span>
                            

                        </div>
                    </div>
                
                    <div className='flex flex-col md:w-full items-center'>

                        <div className='flex mt-8 mb-4 md:mr-12 justify-between'>
                            <Link to='/about' className='md:ml-4 md:mr-4 md:text-m text-sm mr-1 hover:underline'>About</Link> | 
                            <Link to='/terms' className='md:ml-4 md:mr-4 md:text-m text-sm ml-1 mr-1 hover:underline'>Terms and Conditions</Link> |  
                            <Link to='/privacy' className='md:ml-4 md:mr-4 md:text-m text-sm ml-1 hover:underline'>Privacy Policy</Link>
                        </div>

                        <span className='flex m-4 text-sm font-light justify-between text-center
                        dark:text-white'> Built with ❤️ in Madrid, Spain </span>

                    </div> */}
            
        </nav>
    );
}

export default Footer;
