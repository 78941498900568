// React
import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";

// Common
import Footer from "../common/footer";
import Header from '../common/newHeader';

// 3P
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

function PrivacyPolicy () {
  const location = useLocation();
  const {t} = useTranslation();


// ------------------------------------------------------------------------------------------
// Functions
// ------------------------------------------------------------------------------------------
useEffect (() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "PrivacyPolicy" });
        
    // Scroll to top
    window.scrollTo(0, 0);
  }, []);


// ------------------------------------------------------------------------------------------
// Rendering functions
// ------------------------------------------------------------------------------------------
    return (
  <React.Fragment>
    <Header />
    <div className="flex flex-col md:flex-row items-start 
      justify-center 
      p-6 ml-2 mr-2 mt-20 mb-2
      md:p-8 md:ml-12 md:mr-12 md:mt-20 md:mb-4 
    dark:text-white dark:bg-green-800">
      <div className="md:ml-6 md:mr-6 flex flex-col w-full items-start justify-between">
        <div>
          <h1 className="font-light text-2xl text-bold mb-6">Privacy Policy</h1>
        </div>
        <div className="text-justify w-full">
          <span className="mb-6">
            We know that in this digital age, your privacy is important. This
            Privacy Policy reflects our commitment to protecting personal data
            and the choices we offer you regarding how your data is used. We
            welcome you to read more about how we keep your information safe, as
            well as how you can exercise your rights. In addition, our Privacy
            policy covers our treatment of data that may be personal to you.
          </span>
          <ul className="list-disc mt-6">
            <li className="ml-6">
              We will review, update, and amend these policies from time to
              time, consistent with our business needs and technology. We
              encourage you to check back periodically for new updates or
              changes. Your continued use of the service makes up for your
              acceptance of any change to this Privacy Policy. We are the data
              controller of your information. We handle and process all data on
              behalf of our customers
            </li>
            <li className="ml-6">
              You may likewise decide not to give us "discretionary" Personal
              Data; however, please remember that without it, we will most
              likely be unable to provide you with the full scope of our
              administrations or with the best client experience when utilizing
              our Services.
            </li>
            <li className="ml-6">
              This Privacy Policy (“Privacy Policy”) describes how Kincode. Will
              gather, use, and maintain your Personal Information on
              kincode.app. It will also explain your legal rights with respect
              to that information.
            </li>
            <li className="ml-6">
              By using the website or services, you confirm that you have read
              and understood this Privacy Policy and our Terms (together
              referred to herein as the “Agreement”). The Agreement governs the
              use of platform. We will collect, use, and maintain information
              consistent with the Agreement.
            </li>
          </ul>
          <br></br>
          <span className="italic font-bold mb-2">
            What private data do we collect from the people who visit our
            platform?
          </span>
          <br></br>
          When enlisting on our site or buying services as suitable, you could
          be approached to type in your name, email, payment information, or
          different subtleties to assist you with your experience. When you
          create an account and use the Services, including through a
          third-party platform, we collect any data you provide directly,
          including:
          <ul className="list-disc mb-6">
            <li className="ml-6">
              <strong>Account Data</strong>: To use certain features (like Paid
              or unpaid Services), you need to create a user account. When you
              create or update your account, we collect and store the data you
              provide, like your email address, password, and name, and assign
              you a unique identifying number ("Account Data").
            </li>
            <li className="ml-6">
              <strong>Personal Data</strong>: Personal Data is information that
              can be used to identify you specifically, including your name,
              email address, telephone number, mailing address, billing address,
              gender, and date of birth or demographic information like your
              hometown. You consent to give us this information by providing it
              to us voluntarily on our platform. Your decision to disclose this
              data is entirely voluntary. You are under no obligation to provide
              this information, but your refusal may prevent you from accessing
              certain benefits from our platform.
            </li>
            <li className="ml-6">
              <strong>Financial Data</strong>: Financial data is related to your
              payment methods, such as credit card or bank transfer details. We
              collect financial data to allow you to purchase, or exchange
              services from our platform. We store limited financial data. Most
              financial data is transferred to our payment processor,
              Third-party, and you should review these processors' Privacy
              Policies to determine how they use, disclose, and protect your
              financial data.
            </li>
            <li className="ml-6">
              <strong>Contact information</strong>: An Authorized User is
              required to provide some contact information (e.g., an email
              address) when making an account on the Services.
            </li>
            <li className="ml-6">
              <strong>Minors’ Data</strong>:
              <ul className="list-disc">
                <li className="ml-6">
                  We do not knowingly collect data from or market to children
                  under 13 years of age.
                </li>
                <li className="ml-6">
                  We do not knowingly solicit data from or market to children
                  under 13 years of age. By using the Platform, you represent
                  that you are at least 13 or that you are the parent or
                  guardian of such a minor and consent to such minor dependent’s
                  use of the Platform. If we learn that personal information
                  from users less than 13 years of age has been collected, We
                  will take reasonable measures to promptly delete such data
                  from our records. If you become aware of any data we may have
                  collected from children under age 13, please contact us on{" "}
                  <a
                    className="italic font-bold underline"
                    href="mailto:miguel@kincode.app"
                  >
                    {" "}
                    miguel@kincode.app
                  </a>
                  .
                </li>
              </ul>
            </li>
          </ul>
          <br></br>
          <span className="italic font-bold mb-2">
            Automatically collected information about your use of our Services
            or tools
          </span>
          <br></br>
          This information is registered automatically with the visit by the own
          configuration or manual of each tool on the platform
          <ul className="list-disc">
            <li className="ml-6">
              When you visit, connect with, or utilize our service, we may
              gather, record, or create specific specialized data about you. We
              do so either autonomously or with the assistance of third
              gathering Service Providers, including using "cookies" and other
              following innovations.
            </li>
            <li className="ml-6">
              We automatically collect certain information when you visit, use
              or navigate the Platform. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser, and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Platform and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Platform and for our
              internal analytics and reporting purposes.
            </li>
          </ul>
          <br></br>
          <span className="italic font-bold mb-2">
            The information we collect includes:
          </span>
          <ul className="list-disc">
            <li className="ml-6">
              {" "}
              <strong>Log and Usage Data</strong>: Log and usage data are
              service-related, diagnostic, usage, and performance information
              our servers automatically collect when you access or use our
              Platform, which we record in log files. Depending on how you
              interact with us, this log data may include your IP address,
              device information, browser type, settings, and information about
              your activity on the Platform (such as the date/time stamps
              associated with your usage, pages, and files viewed, searches and
              other actions you take such as which features you use), device
              event information (such as system activity, error reports
              (sometimes called 'crash dumps') and hardware settings).
            </li>
            <li className="ml-6">
              {" "}
              <strong>Device Data</strong>: We collect device data such as
              information about your computer, phone, tablet, or another device
              you use to access the Platform. Depending on the device used, this
              device data may include information such as your IP address (or
              proxy server), device and application identification numbers,
              location, browser type, hardware model, Internet service provider
              and/or mobile carrier, operating system, and system configuration
              information.
            </li>
          </ul>
          <br></br>
          <span className="italic font-bold ">How do we use your details?</span>
          <br></br>
          We process personal data to operate, improve, understand, and
          personalize our services. We use personal data for the following
          purposes:
          <ul className="list-disc">
            <li className="ml-6">
              To fulfill or meet the reason you provided the information (e.g.,
              to help provide our Site services to you).
            </li>
            <li className="ml-6">
              To send you catalogs, information, newsletters, promotional
              materials, and other offerings from the Company or on behalf of
              our partners and affiliates.
            </li>
            <li className="ml-6">
              To communicate with you about the Services, including Service
              announcements, updates, or offers.
            </li>
            <li className="ml-6">Correspond with you; and</li>
            <li className="ml-6">
              Compile anonymous statistical data for our own use or for a third
              party's use; and
            </li>
            <li className="ml-6">Assist law enforcement as necessary; and</li>
            <li className="ml-6">
              Analyze trends to improve our platform and offerings.
            </li>
            <li className="ml-6">
              To personalize and develop our site and the services we provide
              you and improve our offerings.
            </li>
            <li className="ml-6">
              To provide certain features or functionality of the services on
              the site.
            </li>
            <li className="ml-6">For marketing and promotions.</li>
            <li className="ml-6">
              To create, maintain, customize, and secure your account with us.
            </li>
            <li className="ml-6">
              To personalize your experience and deliver content and product,
              and services relevant to your interests.
            </li>
            <li className="ml-6">
              To respond to law enforcement requests and as required by
              applicable law, court order, or governmental regulations.
            </li>
            <li className="ml-6">
              To prevent illegal activity, fraud, and abuse.
            </li>
            <li className="ml-6">
              To help our site that will be ready to serve you better.
            </li>
          </ul>
          <br></br>
          We will not collect additional categories of Personal Data or use the
          Personal Data we collected for materially different, unrelated, or
          incompatible purposes without providing you notice.
          <br></br>
          As noted in the list above, we may communicate with you if you've
          provided us with the means to do so. For example, if you've given us
          your email address, we may send you promotional email offers or email
          you about your use of the Services. Also, we may receive a
          confirmation when you open an email from us, which helps us improve
          our services. If you do not want to receive communications from us,
          please indicate your preference by emailing us.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">How to opt-out</span>
          <br></br>
          When you engage us for the Services or make inquiries about our
          Services, you will be requested to provide your consent to us to send
          promotional material to you. You may stop the delivery or “opt-out” of
          future promotional emails by following the specific instructions in
          the email you receive, or alternatively, sending us an email to{" "}
          <a
            href="mailto:miguel@kincode.app"
            className="underline italic font-bold"
          >
            miguel@kincode.app
          </a>
          .<br></br>
          <br></br>
          <span className="italic font-bold mb-2">Do Not Track</span>
          <br></br>
          Currently, various browsers — such as Internet Explorer, Firefox, and
          Safari — offer a “do not track” or “DNT” option that relies on a
          technology known as a DNT header, which sends a signal to Web sites
          visited by the user about the user's browser DNT preference setting.
          Kincode does not currently commit to responding to browsers' DNT
          signals concerning the Company's Web sites, in part because industry
          groups have adopted no common industry standard for DNT, technology
          companies, or regulators, including no consistent standard of
          interpreting user intent. Kincode takes privacy and meaningful choice
          seriously and will make efforts to continue to monitor developments
          around DNT browser technology and the implementation of a standard.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">How we use cookies</span>
          <br></br>
          We use cookies and similar technologies for several purposes,
          depending on the context or service, including:
          <ul className="list-disc"></ul>
          <li className="ml-6">
            Recognize you if you sign in to use our offerings. This allows us to
            provide you with recommendations, display personalized content, and
            provide other customized features and services.
          </li>
          <li className="ml-6">
            We are keeping track of your specified preferences. This allows us
            to honor your likes and dislikes, such as your language and
            configuration preferences.
          </li>
          <li className="ml-6">
            Detecting and preventing fraudulent activity and improving security.
          </li>
          <li className="ml-6">
            We are conducting research and diagnostics to improve our offerings.
          </li>
          <li className="ml-6">
            Reporting allows us to measure and analyze the performance of our
            offerings.
          </li>
          <br></br>
          <span className="italic  mb-2">First-party cookies</span>
          <br></br>
          <ul>
            <li className="ml-6">
              a) Strictly Necessary Cookies: These cookies are necessary for the
              platform to function and cannot be switched off in our systems.
              They are often set in response to actions made by you, which
              amount to a request for services, such as setting your privacy
              preferences, logging in, or filling in forms.
            </li>
            <li className="ml-6">
              b) Functional cookies: These cookies enable the platform to
              provide enhanced functionality and personalization. They may be
              set by third-party providers whose services we have added to our
              pages or by us.
            </li>

            <li className="ml-6">
              c) Performance cookies: These cookies allow us to count visits and
              traffic sources to measure and improve the performance of our
              site. They help us know which pages are the most and least popular
              and see how visitors move around the site.
            </li>

            <li className="ml-6">
              d) Targeting cookies: These cookies may be set through our site
              and used to build a profile of your interests and show you
              relevant adverts on other sites.
            </li>
          </ul>
          <br></br>
          <span className="italic  mb-2">Google Analytics Cookies</span>
          <br></br>
          We use Google Analytics to investigate the utilization of our site by
          users and visitors. Google Analytics assembles data about site use
          through cookies. The data assembled identifying our site is utilized
          to make reports about the utilization of our site. You can refuse the
          use of Google Analytics by clicking on the following link. An opt-out
          cookie will be set on the computer, which prevents the future
          collection of your data when visiting this website: Disable Google
          Analytics.
          <table className="table-auto border border-black text-sm">
            <thead>
              <tr className="bg-black	text-white">
                <th className="border border-white p-2">Name of cookie </th>
                <th className="border border-white p-2 p-2">Expiry period </th>
                <th className="border border-white p-2 p-2">Purpose</th>
                <th className="border border-white p-2 p-2">Company</th>
              </tr>
            </thead>
            <tr>
              <td className="border border-black p-2">_ga</td>
              <td className="border border-black p-2">Persistent - 2 years</td>
              <td className="border border-black p-2">Analytics </td>
              <td className="border border-black p-2">
                Google Analytics, Google LLC
              </td>
            </tr>
            <tr>
              <td className="border border-black p-2">_ga_JMDY11H0YJ </td>
              <td className="border border-black p-2">Persistent - 2 years</td>
              <td className="border border-black p-2">Analytics </td>
              <td className="border border-black p-2">
                Google Analytics, Google LLC
              </td>
            </tr>
            <tr>
              <td className="border border-black p-2">_gat </td>
              <td className="border border-black p-2">Persistent - 2 years</td>
              <td className="border border-black p-2">Analytics </td>
              <td className="border border-black p-2">
                Google Analytics, Google LLC
              </td>
            </tr>
            <tr>
              <td className="border border-black p-2">_gid </td>
              <td className="border border-black p-2">Persistent - 1 day</td>
              <td className="border border-black p-2">Analytics </td>
              <td className="border border-black p-2">
                Google Analytics, Google LLC
              </td>
            </tr>

            <tr>
              <td className="border border-black p-2">gaVisitorTypeLong </td>
              <td className="border border-black p-2">Persistent - 2 years</td>
              <td className="border border-black p-2">Analytics </td>
              <td className="border border-black p-2">
                Google Analytics, Google LLC
              </td>
            </tr>
            <tr>
              <td className="border border-black p-2">gaVisitorTypeShort </td>
              <td className="border border-black p-2">
                Persistent - 30 minutes
              </td>
              <td className="border border-black p-2">Analytics </td>
              <td className="border border-black p-2">
                Google Analytics, Google LLC
              </td>
            </tr>
            <tr>
              <td className="border border-black p-2">
                _sp_root_domain_test_* (x 1535)
              </td>
              <td className="border border-black p-2"> Session cookie</td>
              <td className="border border-black p-2"> Analytics</td>
              <td className="border border-black p-2"> kincode.app</td>
            </tr>
          </table>
          <br></br>
          <span className="italic  mb-2">
            What are your choices regarding cookies?
          </span>
          <br></br>
          <strong>Cookie Preferences on the website</strong>: Our cookies allow
          you to take advantage of some essential and useful features. Blocking
          some types of cookies may impact your experience of our sites. You can
          change your cookie preferences at any time by clicking ‘Cookie
          Preferences in the footer of the website.
          <br></br>
          <strong>Browser settings</strong>: You can also manage browser cookies
          through your browser settings. The 'Help' feature on most browsers
          will tell you how to remove cookies from your device, prevent your
          browser from accepting new cookies, how to have the browser notify you
          when you receive a new cookie, how to disable cookies, and when
          cookies will expire. Check the support site for your browser to
          understand the privacy settings available to you. If you block or
          reject some of our cookies through your browser’s settings, some
          features and services may not work. You might also have to manually
          adjust your preferences or settings every time you visit our platform.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">
            GDPR-Customer data processing appendix
          </span>
          <br></br>
          <strong>Customer Data</strong> means any personal data that Kincode
          processes on Customer's behalf via the Services, as more particularly
          described in this DPA (Data Protection Agreement).
          <br></br>
          <strong>Data Protection Laws</strong> means all data protection laws
          and regulations applicable to a party’s processing of Customer Data
          under the Agreement, including, where applicable, EU Data Protection
          Law and Non-EU Data Protection Laws.
          <br></br>
          <br></br>
          <strong>GDPR-EU data protection law </strong>
          <br></br>
          <strong>EU Data Protection Law</strong> means all data protection laws
          and regulations applicable to Europe, including (i) Regulation
          2016/679 of the European Parliament and the Council on the protection
          of natural persons concerning the processing of personal data and on
          the free movement of such data (General Data Protection Regulation)
          (“GDPR“); (ii) Directive 2002/58/EC concerning the processing of
          personal data and the protection of privacy in the electronic
          communications sector; (iii) applicable national implementations of
          (i) and (ii); and (iv) in respect of the United Kingdom (“UK“) any
          applicable national legislation that replaces or converts in domestic
          law the GDPR or any other law relating to data and privacy as a
          consequence of the UK leaving the European Union. “Europe” means, for
          this DPA, the European Union, the European Economic Area and/or their
          member states, Switzerland, and the United Kingdom.
          <br></br>
          <strong>Non-EU Data Protection Laws</strong> means the California
          Consumer Privacy Act (“CCPA”); the Canadian Personal Information
          Protection and Electronic Documents Act (“PIPEDA”); and the Brazilian
          General Data Protection Law (“LGPD “), Federal Law no. 13,709/2018.
          <br></br>
          <strong>SCCs</strong> means the standard contractual clauses for
          processors as approved by the European Commission or Swiss Federal
          Data Protection Authority (as applicable), which shall be applied only
          to transfers of Customer Data from the European Union.
          <br></br>
          <strong>Services Data</strong> means any data relating to the
          Customer’s use, support, and/or operation of the Services, including
          information relating to volumes, activity logs, frequencies, bounce
          rates, or other information regarding emails and other communications
          the Customer generates and sends using the Services.
          <ul className="list-disc ml-6">
            <li>
              <strong>Parties' roles</strong>: If EU Data Protection Law or the
              LGPD applies to either party's processing of Customer Data, the
              parties acknowledge and agree that concerning the processing of
              Customer Data, the Customer is the controller and is a processor
              acting on behalf of Customer, as further described in Annex A
              (Details of Data Processing) of this DPA.
            </li>
            <li>
              <strong>Purpose limitation</strong>: Kincode shall process
              Customer Data only following Customer's documented lawful
              instructions as outlined in this DPA, as necessary to comply with
              applicable law, or as otherwise agreed in writing ("Permitted
              Purposes"). The parties agree that the agreement sets out the
              Customer's complete and final instructions to Kincode concerning
              the processing of Customer Data. Processing outside the scope of
              these instructions (if any) shall require a prior written
              agreement between the parties.
            </li>
            <li>
              <strong>Prohibited data</strong>: The customer will not provide
              (or cause to be provided) any Sensitive Data to Kincode for
              processing under the Agreement, and Kincode will have no liability
              whatsoever for Sensitive Data, whether in connection with a
              Security Incident or otherwise. To avoid doubt, this DPA will not
              apply to Sensitive Data.
            </li>
            <li>
              <strong>Customer compliance</strong>: Customer represents and
              warrants that (i) it has complied, and will continue to comply,
              with all applicable laws, including Data Protection Laws, in
              respect of its processing of Customer Data and any processing
              instructions it issues to Kincode; and (ii) it has provided, and
              will continue to provide, all notice and has obtained, and will
              continue to obtain, all consents and rights necessary under Data
              Protection Laws for Kincode to process Customer Data for the
              purposes described in the agreement. Customer shall have sole
              responsibility for the accuracy, quality, and legality of Customer
              Data and how Customer acquired Customer data. Without prejudice to
              the generality of the preceding, Customer agrees that it shall be
              responsible for complying with all laws (including Data Protection
              Laws) applicable to any emails or other content created, sent, or
              managed through the service, including those relating to obtaining
              consents (where required) to send emails, the content of the
              emails and its email deployment practices.
            </li>
            <li>
              <strong>The lawfulness of Customer's instructions</strong>: The
              customer will ensure that United Kingdom processing of the
              Customer Data by Customer's instructions will not cause Kincode to
              violate any applicable law, regulation, or rule, including,
              without limitation, Data Protection Laws. Kincode shall promptly
              notify Customer in writing unless prohibited from doing so under
              EU Data Protection Laws if it becomes aware or believes that any
              data processing instruction from Customer violates the GDPR or any
              UK implementation of the GDPR.
            </li>
          </ul>
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">Your Legal Rights</span>
          <br></br>
          Under certain circumstances, you have rights under data protection
          laws to your data. You may have the following rights: -
          <ul>
            <li className="ml-6">
              A. Request access to your data (commonly known as a "data subject
              access request"). This enables you to receive a copy of the
              personal data we hold about you and check that we are lawfully
              processing it.
            </li>
            <li className="ml-6">
              B. Request correction of the personal data that we hold about you.
              This enables you to have any incomplete or inaccurate data we hold
              about you corrected, though we may need to verify the accuracy of
              the new data you provide.
            </li>
            <li className="ml-6">
              C. Request deletion of your data. This enables you to ask us to
              delete or remove personal data where there is no good reason to
              continue processing it. You also have the right to ask us to
              delete or remove your data where you have successfully exercised
              your right to object to processing (see below), where we may have
              processed your information unlawfully or where we are required to
              erase your data to comply with local law. Note, however, that we
              may not always be able to comply with your request of erasure for
              specific legal reasons, which will be notified to you, if
              applicable, at the time of your request.
            </li>

            <li className="ml-6">
              D. Object to processing your data where we are relying on a
              legitimate interest (or those of a third party). Something about
              your situation makes you want to object to processing on this
              ground as you feel it impacts your fundamental rights and
              freedoms. You also have the right to object to processing your
              data for direct marketing purposes. In some cases, we may
              demonstrate that we have compelling legitimate grounds to process
              your information, overriding your rights and freedoms.
            </li>

            <li className="ml-6">
              E. Request restriction of processing of your data. This enables
              you to ask us to suspend the processing of your data in the
              following scenarios:
              <ul className="list-disc">
                <li className="ml-8">
                  a. If you want us to establish the data's accuracy.
                </li>
                <li className="ml-8">
                  b. Our use of the data is unlawful, but you do not want us to
                  erase it.
                </li>
                <li className="ml-8">
                  c. You need us to hold the data even if we no longer require
                  it as you need it to establish, exercise, or defend legal
                  claims.
                </li>
                <li className="ml-8">
                  d. You have objected to our use of your data, but we need to
                  verify whether we have overriding legitimate grounds to use
                  it.
                </li>
              </ul>
            </li>
            <li className="ml-6">
              F. Request the transfer of your data to you or a third party. We
              will provide your data in a structured, commonly used,
              machine-readable format to you or a chosen third party. Note that
              this right only applies to automated information; you initially
              provided consent for us to use or used the information to perform
              a contract with you.
            </li>
            <li className="ml-6">
              G. Withdraw consent at any time where we are relying on consent to
              process your data. However, this will not affect the lawfulness of
              any processing carried out before you withdraw your consent. If
              you withdraw your consent, we may not provide certain services to
              you.
            </li>
          </ul>
          <br></br>
          <span className="italic  mb-2">How do we protect your details?</span>
          <ul className="list-disc ml-6">
            <li>
              We have implemented industry-accepted administrative, physical,
              and technology-based security measures to protect against the
              loss, misuse, unauthorized access, and alteration of personal
              information in our systems. We ensure that any employee,
              contractor, corporation, organization, or vendor who has access to
              personal information in our systems are subject to legal and
              professional obligations to safeguard that personal information.
            </li>
            <li>
              We do not use vulnerability scanning and/or scanning to PCI
              specifications.
            </li>
            <li>We use regular Malware Scanning.</li>
            <li>
              We implement several security measures whenever a user gets into,
              submits, or accesses their information to protect your individual
              information.
            </li>
            <li>
              While we strive to use commercially acceptable means to protect
              your personal information, no method of transmission over the
              Internet or form of electronic storage is 100 percent secure.
              Therefore, we cannot guarantee its absolute security.
            </li>
            <li>
              Kincode prohibits unauthorized access or use of personal
              information stored on our servers. Such access is a violation of
              law, and we will fully investigate and press charges against any
              party that has illegally accessed the information within our
              systems.
            </li>
          </ul>
          <br></br>
          <span className="italic  mb-2">Can-spam act</span>
          <br></br>
          The CAN-SPAM Act is a regulation that sets the guidelines for
          commercial email, establishes requirements for commercial
          announcements, offers recipients to have emails ceased from being
          delivered to them, and spells out hard fines for violations. We
          accumulate your email to be able to:
          <ul className="list-disc ml-6">
            <li>
              Send information, react to questions, and/or other demands or
              questions
            </li>
            <li>
              To maintain compliance with CANSPAM, we consent to the next:
            </li>
            <li>
              Not use untrue or misleading subject matter or email addresses.
            </li>
            <li>
              Identify the concept as an advertisement in some realistic way.
            </li>
            <li>
              Include the physical address of our site headquarters or business
            </li>
            <li>
              Screen third-party email marketing services for conformity, if one
              can be used.
            </li>
            <li>Honor opt-out/unsubscribe demands quickly.</li>
            <li>
              Allow users to unsubscribe utilizing the link at the bottom of
              every email.
            </li>
          </ul>
          <br></br>
          If anytime you want to unsubscribe from receiving future emails, you
          can email us by using the contact form at our platform, and we'll
          immediately remove you from ALL communication.
          <br></br>
          <br></br>
          <span className="italic  mb-2">Limitation of liability</span>
          <br></br>
          Some jurisdictions do not allow the limitation or exclusion of
          liability for incidental or consequential damages, so some of the
          above limitations may not apply to you. We make no legal
          representation that the platform or products are appropriate or
          available in locations outside Spain. You may access the platform from
          outside Spain.at your own risk and initiative and must bear all
          responsibility for compliance with applicable foreign laws.
          <br></br>
          <br></br>
          <span className="italic  mb-2">Governing Law and Jurisdiction</span>
          <br></br>
          This platform originates from Spain. The laws of Spain. Without regard
          to its conflict of law, principles will govern these terms to the
          contrary. You hereby agree that all disputes arising out of or in
          connection with these terms shall be submitted to the exclusive
          jurisdiction of Spain. Using this platform, you consent to the
          jurisdiction and venue of such courts in connection with any action,
          suit, proceeding, or claim to arise under or because of these terms.
          You hereby waive any right to trial by jury arising out of these
          terms. Changes to this privacy notice We're constantly trying to
          improve our Services, so we may need to change this Privacy Policy
          from time to time, but we will alert you to changes by placing a
          notice on the Kincode platform by sending you an email and/or by some
          other means. Please note that if you've opted not to receive legal
          notice emails from us (or you haven't provided us with your email
          address), those legal notices will still govern your use of the
          Services, and you are still responsible for reading and understanding
          them. If you use the Services after any changes to the Privacy Policy
          have been posted, that means you agree to all the changes. The use of
          the information we collect is subject to the Privacy Policy in effect
          at the time such information is collected.
          <br></br>
          <br></br>
          <span className="italic  mb-2">Contacting us</span>
          <br></br>
          If you would like to contact us to understand more about this Policy
          or wish to contact us concerning any matter relating to individual
          rights and your Personal Information, you may do so via the contact us
          or email us.
          <br></br>
          <br></br>
          This document was last updated on August 22, 2022
        </div>
      </div>
    </div>
    <Footer />

  </React.Fragment>
);
}

export default PrivacyPolicy;
