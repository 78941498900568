// React
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

// Common
import Header from '../../common/newHeader';
import Footer from '../../common/footer';

// 3P
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

// Widgets
import Testimonials from '../../widgets/testimonials';
import CTA from '../../widgets/cta';
import CustomersWidget from '../../widgets/customers';
import TextAndImage from '../../widgets/textAndimage';

function Basics () {
  const location = useLocation();
  const {t} = useTranslation();


// ------------------------------------------------------------------------------------------
// Functions
// ------------------------------------------------------------------------------------------
useEffect (() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Product-Basics" });

    // Scroll to top
    window.scrollTo(0, 0);
  
  }, []);


  // ------------------------------------------------------------------------------------------
  // Render function
  // ------------------------------------------------------------------------------------------
  return(
      <React.Fragment>
        <Header/>

        <div className='flex flex-col items-center h-auto w-auto'>
            {/* Headline */}
            <div id="main" className='flex flex-col space-y-12 lg:space-y-0 lg:flex-row items-center lg:justify-center w-full px-8 lg:px-40 py-24 bg-orange-50'>
                {/* Text left-side */}
                <div className='flex flex-col items-start w-full lg:w-1/2 space-y-4'>
                    {/* Section title */}
                    <h1 className='text-left text-lg font-bold text-[#63C7AA]'>{t('product.basics.title')}</h1>
                    {/*  Section description */}

                    {/* Section key message */}
                    <h2 className='widget-keyMessage-title'>{t('product.basics.keyMessage')}</h2>

                    {/* Section description */}
                    <p className='widget-keyMessage-description'>{t('product.basics.keyMessage.description')}</p>

                    {/* Section key bullet points */}
                    <div className='flex flex-col items-start text-left space-y-4 justify-between w-full pt-4'>
                        <div className='widget-bulletPoints'><span className='mr-4'>🏖️</span>{t('product.basics.bullet1')}</div>
                        <div className='widget-bulletPoints'><span className='mr-4'>👤</span>{t('product.basics.bullet2')}</div>
                        <div className='widget-bulletPoints'><span className='mr-4'>📥</span>{t('product.basics.bullet3')}</div>
                    </div>


                </div>

                {/* Image right side */}
                <div className='flex flex-col items-center justify-center w-full lg:w-2/3 pt-8'>
                    <div className='container content-fit mx-auto lg:w-2/3 max-w-sm lg:max-w-xl overflow-hidden'>
                        <img className='rounded-lg object-cover w-full h-auto shadow rounded-lg' 
                            alt="Kincode App - engagement platform " 
                            src="/assets/illustrations/basics-illustration.webp" />
                    </div>
                </div>

            </div>

            {/* Customers */}
            <CustomersWidget />

            {/* Vacaciones y ausencias */}
            <TextAndImage 
                title={t('product.holidays.title')}
                keyMessage={t('product.holidays.keyMessage')}
                keyMessageDescription={t('product.holidays.keyMessage.description')}
                bulletPoint1={t('product.holidays.bullet1')} bulletPoint1Emoji="🔄"
                bulletPoint2={t('product.holidays.bullet2')} bulletPoint2Emoji="⏰"
                bulletPoint3={t('product.holidays.bullet3')} bulletPoint3Emoji="💡"
                img="/assets/illustrations/holidays/basics-holiday-illustration.webp"
                alt="Kincode App - engagement platform" 
                urlLearnMore={'/product/basics/timeoff/'}
                orientation="left"
            />


            {/* Perfil del empleado */}
            <TextAndImage
                title={t('product.profile.title')}
                keyMessage={t('product.profile.keyMessage')}
                keyMessageDescription={t('product.profile.keyMessage.description')}
                bulletPoint1={t('product.profile.bullet1')} bulletPoint1Emoji="👤"
                bulletPoint2={t('product.profile.bullet2')} bulletPoint2Emoji="📈"
                bulletPoint3={t('product.profile.bullet3')} bulletPoint3Emoji="🛠️"
                img="/assets/illustrations/profile/basics-profile-illustration.webp"
                alt="Kincode App - engagement platform" 
                urlLearnMore={'/product/basics/profile/'}
                orientation="right"
            />

            {/* Testimonials  */}
            <Testimonials />

            {/* Organigram */}
            <TextAndImage
                title={t('product.organigram.title')}
                keyMessage={t('product.organigram.keyMessage')}
                keyMessageDescription={t('product.organigram.keyMessage.description')}
                bulletPoint1={t('product.organigram.bullet1')} bulletPoint1Emoji="🌳"
                bulletPoint2={t('product.organigram.bullet2')} bulletPoint2Emoji="🤝"
                bulletPoint3={t('product.organigram.bullet3')} bulletPoint3Emoji="🔍"
                img="/assets/illustrations/organigram/basics-organigram-illustration.webp"
                alt="Kincode App - engagement platform"
                urlLearnMore={'/product/basics/organigram/'}
                orientation="left"
            />

            {/* Inbox */}
            <TextAndImage
                title={t('product.inbox.title')}
                keyMessage={t('product.inbox.keyMessage')}
                keyMessageDescription={t('product.inbox.keyMessage.description')}
                bulletPoint1={t('product.inbox.bullet1')} bulletPoint1Emoji="📝"
                bulletPoint2={t('product.inbox.bullet2')} bulletPoint2Emoji="🗣️"
                bulletPoint3={t('product.inbox.bullet3')} bulletPoint3Emoji="📬"
                img="/assets/illustrations/inbox/basics-inbox-illustration.webp"
                alt="Kincode App - engagement platform"
                urlLearnMore={'/product/basics/inbox/'}
                orientation="right"
            />

            {/* Book a demo - or free trial CTA */}
            <CTA />

        </div>
        
        
        <Footer/>

      </React.Fragment>
  );

}

export default Basics;