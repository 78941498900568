// React
import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';

// 3P
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';

// Common
import Configs from '../Configs';




// import { BiLogInCircle, BiLogOutCircle } from 'react-icons/bi';

function Bot (props) {
    const {t, i18n} = useTranslation();

    const location = useLocation();
    
    // To understand if chat is open or closed
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const startUp = [
        {
            message: null,
            response: "¡Hola! Mi nombre es Carolina, ¿cómo puedo ayudarte?"
        }
    ]

    // State variables
    const [customerMessage, setCustomerMessage] = useState("");
    const [chatHistory, setChatHistory] = useState(startUp);
    
// ------------------------------------------------------------------------------------------
// Functions
// ------------------------------------------------------------------------------------------
    useEffect (() => {
        

      }, []);

// ------------------------------------------------------------------------------------------
// API Calls
// ------------------------------------------------------------------------------------------
    async function getResponse (event) {
        // prevent button from refreshing the entire page
        event.preventDefault();

        // Send data to GA-4
        ReactGA.event({
            category: "bot",
            action: "Bot Submit",
            label: "Chatbot - Submit button clicked", // optional
            // value: 99, // optional, must be a number
            // nonInteraction: true, // optional, true/false
            // transport: "xhr", // optional, beacon/xhr/image
          });


        // Create a feeling of working on something
        setLoading(true);
        var auxArray = chatHistory;

        const auxElement = {
            message: customerMessage,
            response: "Escribiendo..."
        }
        setChatHistory([...chatHistory, auxElement]);


        // define header and HTTP request type
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json' },
            body: JSON.stringify({ 
                // username must have email format 
                message: customerMessage,
            })
        };

        fetch(Configs.openAIAPI, requestOptions)
        .then(response => {
            if (response.status === 200) {

                    response.json().then ( body => {
                        // Token was received, and we navigate to another page
                        if (Configs.devEnvironment) console.log ("Response from ChatGPT is: ", body.response);
                        setLoading(false);
                        // Remove last element - Loading fake
                        auxArray.slice(0,-1);

                        // Create object to add to chat
                        const addToChat = {
                            message: customerMessage,
                            response: body.response
                        }

                        // Update chat history
                        setChatHistory([...auxArray, addToChat]);

                        // Clean-up
                        setCustomerMessage("");
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                    // 
                    throw response
            }
            })
        .catch (error => {
            console.log ("Error fetching data: ", error, " - function: Login");
        })
    }

    // ------------------------------------------------------------------------------------------
    // Frontend functions
    // ------------------------------------------------------------------------------------------
    function logout (e) {
        e.preventDefault();

    }

    async function openCloseBot (e) {
        e.preventDefault();
        setIsOpen(!isOpen);

        ReactGA.event({
            category: "bot",
            action: "Bot Open/Close",
            label: "Chatbot - user opening/closing bot", // optional
            // value: 1, // optional, must be a number
            // nonInteraction: true, // optional, true/false
            // transport: "xhr", // optional, beacon/xhr/image
          });
    }

    // ------------------------------------------------------------------------------------------
    // Rendering functions
    // ------------------------------------------------------------------------------------------
    return (
        <div className='fixed bottom-0 right-0 sm:bottom-4 sm:right-4 z-50'>


            {
            /** 
             * Collapsed version of the chatbot
             */
                }
            <div className={ isOpen ?  ' hidden ' : '  flex flex-row items-center '} onClick={(e) => openCloseBot(e)}>
                    
                <div className='flex flex-wrap hidden border border-2 border-gray-400 hover:border-yellow-400 
                                 p-4 text-left text-black bg-white text-sm max-h-[75px] shadow-2xl
                                    '> 
                    {chatHistory[0].response}
                </div>

                <div className='bg-yellow-400 hover:bg-yellow-600 text-white p-2 rounded-full shadow-2xl '>
                    <div className='container w-[65px] h-[65px]' >
                        <img className='rounded-full' src='/assets/Carol@2x.png'>
                        </img>
                    </div>
                </div>  
            </div>

            {
            /** 
             * Expanded version of the chatbot --> THE CHAT
             */
            }
            <div className={isOpen ?  ' w-full h-full sm:w-auto sm:h-auto ' : ' hidden '}>
                <div className='flex flex-col shadow-2xl'>
                    <div className='flex flex-row bg-yellow-400 justify-between items-center h-[60px]' onClick={(e) => openCloseBot(e)}>
                        <div className='flex p-4 '>
               
                        </div>
                        <div className='p-4 font-bold'>
                            Chat
                        </div>
                        <div className='p-4 font-bold'>
                            X
                        </div>
                    </div>

                <div className='flex flex-col w-full h-full sm:h-auto '>
                    <div className='flex w-full h-full sm:w-96 sm:h-80 bg-white overflow-y-auto text-sm'>
                        <ul className='w-full'>
                            {chatHistory.map((chat, index) => (
                                <li className='pb-2' key={index}>
                                  
                                        {chat.message != null ? 
                                            <div className='text-left p-2 border border-gray m-2 rounded '>
                                                 <div className='flex flex-row mt-2'> 
                                                    <span className='font-bold mb-2'>User:</span>
                                                </div>
                                                {chat.message}
                                            </div>
                                        :
                                            null 
                                        }

                                        {chat.response != null ? 
                                            <div className='text-left p-2 border border-gray m-2 rounded '>
                                                <div className='flex flex-row mt-2'> 
                                                    <div className='container w-[20px] h-[20px]'>
                                                        <img className='rounded-full' src='https://www.dropbox.com/s/ahtrov3ng0y46uc/Carol%402x.png?raw=1' />
                                                    </div>
                                                    <span className='font-bold ml-1 mb-2'>Carolina:</span>
                                                </div>
                                                <span className={(loading && chat.response.toString() === "Escribiendo...") ? '  animate-pulse ' : ' whitespace-pre-line '}>
                                                 {chat.response}
                                                </span>
                                            </div>
                                        :
                                        null
                                        }
 
                                </li>
                            ))}
                        </ul>
                    </div>

                    <form class="flex bg-white border-t-2 text-black border-gray-100 px-4 py-2 text-sm" onSubmit={getResponse}>
                        <div class="flex">
                            <input type="text" class="flex-grow px-4 py-2 border border-gray-300 rounded-lg mr-2" placeholder="Escribe tu mensaje..." value={customerMessage}
                            onChange = {(e) => setCustomerMessage(e.target.value)}/>
                            <button type="submit" class="px-4 py-2 bg-yellow-400 hover:bg-yellow-600 hover:text-white text-black font-bold rounded-lg ">
                                Enviar
                            </button>
                        </div>
                    </form>
                </div>
                </div>
            </div>

        </div>



    );    
}

export default Bot;
