// React
import React, {useEffect} from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

// 3P
import ReactGA from 'react-ga4';
import { useTranslation } from "react-i18next";

// Header & Footer
import Header from '../../common/newHeader';
import Footer from '../../common/footer';
import Customers from '../../widgets/customers';

// Sections within Landing Page
import {keyMessages} from '../../data/keyMessages';
import CTA from "../../widgets/cta";
import TextAndImage from "../../widgets/textAndimage";
import Testimonials from "../../widgets/testimonials";


// ------------------------------------------------------------------------------------------
function ToxicCulture () {

  // Variables
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // ------------------------------------------------------------------------------------------
  // useEffect
  // ------------------------------------------------------------------------------------------
  useEffect (() => {
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "ToxicCulture" });
            
        // Scroll to top
        window.scrollTo(0, 0);
  }, []);

  // ------------------------------------------------------------------------------------------
  // Frontend functions
  // ------------------------------------------------------------------------------------------
  async function handleDemoButton (e) {
    // Avoid button default behavior - refresh page
    e.preventDefault();

    // Log GA4 event
    ReactGA.event({
      category: "conversion",
      action: `Free advisory button clicked: ${e.target.id}`,
      label: "ToxicCulture - Demo button clicked", // optional
    });

    // Open a new window and go to calendly.com
    window.open('https://calendly.com/kincode-app', '_blank');
    
}

  // ------------------------------------------------------------------------------------------
  // Rendering functions
  // ------------------------------------------------------------------------------------------
  return (
    
      <React.Fragment>
          <Header />

          {/* Main section - Relative positioning */}
          <div className="relative -top-60">
              {/* <Bot /> */}

                  {/* Gradient */}
                    <img 
                      src="/assets/images/home-handshake.png"
                      alt="Kincode App - Culture-based employee engagement platform"
                      className="absolute top-0 left-0 right-0 object-fill w-full"
                      />
                    <img 
                      src="/assets/images/home-gradient.png"
                      alt="Kincode App - Culture-based employee engagement platform"
                      className="absolute top-0 left-0 right-0 opacity-90 object-fill w-full"
                    />


                  {/* Main section - absolute - VSL */}
                  <div className="absolute flex flex-col lg:flex-row items-center lg:items-start justify-center top-0 right-0 left-0 mt-8 lg:mt-36 lg:mx-20 z-10">
                    <div className="flex flex-col items-center w-1/2 rounded-lg mx-8 space-y-8 hidden lg:block">
                            <h1 className="text-center font-black text-white text-2xl lg:text-4xl !leading-snug  ">
                              {t('kincode.toxic-culture.tagline')}
                            </h1>
                            <span className="text-justify text-white text-lg m-4 font-medium
                              hidden
                              lg:block">
                              {t('kincode.toxic-culture.tagline.second')}
                            </span>
                            <button id="main-cta-button" className='cta-button z-10' onClick={(e) => handleDemoButton(e)}>
                              {t('cta.toxic-culture')}
                            </button>
                    </div>
                    <div className="flex flex-col items-center w-full lg:w-1/2 rounded-lg ">
                      <div style={{ position: "relative", paddingBottom:"55.956112852664575%", height:0, width:'100%'}}>
                            <iframe
                            title="Kincode - Video Sales Letter" 
                            src="https://www.loom.com/embed/a237713098f1480c815a4db9383e6e41?sid=a74d39cf-380b-43fd-9c34-0e9677c068ba" 
                            frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen 
                            style={{ position: "absolute", top: 0, left: 0, right:0, width: "100%", height: "100%" }}
                            >
                            </iframe>
                        </div>
                      </div>
                          
                  </div>
                  
                </div>

              <div className="relative lg:mt-80 lg:pt-80">

                {/* Customers section - relative */}
                <div className=" mt-80 lg:mt-40 lg:pt-40 pt-40 md:pt-80 ">
                      <div className="flex flex-col items-center w-full rounded-lg mt-10 mx-8 space-y-8 lg:hidden block mb-12">
                                  <h1 className="text-center font-black text-black text-2xl lg:text-4xl !leading-snug  ">
                                    {t('kincode.toxic-culture.tagline')}
                                  </h1>
                                  <button id="main-cta-button" className='cta-button z-10' onClick={(e) => handleDemoButton(e)}>
                                    {t('cta.toxic-culture')}
                                  </button>
                          </div>
                  <Customers />
                </div>

                {/* Testimonials */}
                <Testimonials />

                {/* Three modules - value prop */}
                {/* ENGAGE */}
                <TextAndImage
                    title={t('product.engage.title')}
                    keyMessage={t('product.engage.keyMessage')}
                    keyMessageDescription={t('product.engage.keyMessage.description')}
                    bulletPoint1={t('product.engage.bullet1')} bulletPoint1Emoji="📈"
                    bulletPoint2={t('product.engage.bullet2')} bulletPoint2Emoji="🤝"
                    bulletPoint3={t('product.engage.bullet3')} bulletPoint3Emoji="🌟"
                    img="/assets/illustrations/enagement-illustration.webp"
                    alt="Kincode App - engagement platform"
                    urlLearnMore={'/product/engage/'}
                    orientation="left"
                />




                      

                {/* CTA */}
                <div className="widget-container bg-green-200">
                    <div className='flex flex-col items-center space-y-8'>
                        <div className='flex flex-col items-center space-y-2'>
                            <h2 className="widget-keyMessage-title">{t('widgets.cta.toxic-culture.title')}</h2>
                            <p className="widget-keyMessage-description">{t('widgets.cta.toxic-culture.description')}</p>
                        </div>
                        <Link to="/contact/" >
                            <button className="cta-button">
                            {t('widgets.cta.toxic-culture.button')}
                            </button>
                        </Link>
                    </div>
                </div>
        </div>


    <Footer />

    </React.Fragment>
  );

}


export default ToxicCulture;