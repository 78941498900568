// React
import React, {useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";

// 3P
import ReactGA from 'react-ga4';
import { useTranslation } from "react-i18next";

// Header & Footer
import Header from '../common/newHeader';
import Footer from '../common/footer';
import PricingWidget from '../common/pricingWidget';
import Bot from '../common/bot';
import Customers from '../widgets/customers';

// Sections within Landing Page
import {keyMessages} from '../data/keyMessages';
import CTA from "../widgets/cta";
import TextAndImage from "../widgets/textAndimage";
import Testimonials from "../widgets/testimonials";


function App() {
  // ------------------------------------------------------------------------------------------
  // State variables
    // 👇️ with React router 
  const location = useLocation();
  const navigate = useNavigate();
  const {t} = useTranslation();

  useEffect (() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Home" });

    // Scroll to top
    window.scrollTo(0, 0);
    
  }, []);

  // ------------------------------------------------------------------------------------------
  // Frontend functions
  // ------------------------------------------------------------------------------------------
  async function handleDemoButton (e) {
        // Avoid button default behavior - refresh page
        e.preventDefault();

        // Log GA4 event
        ReactGA.event({
          category: "conversion",
          action: `Demo button clicked: ${e.target.id}`,
          label: "Home - Demo button clicked", // optional
        });
    
        // Navigate to contact page
        navigate("/contact");
  }

  // ------------------------------------------------------------------------------------------
  // Rendering functions
  // ------------------------------------------------------------------------------------------
  return (
    <React.Fragment> 
        {/* <!-- Google Tag Manager (noscript) --> */}
        {/* <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WHVBJ5J9"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> */}
        {/* <!-- End Google Tag Manager (noscript) -->      */}


        <Header/>

        
              {/* Main section - Relative positioning */}
              <div className="relative -top-60">
              {/* <Bot /> */}

                  {/* Gradient */}
                    <img 
                      src="/assets/images/home-handshake.png"
                      alt="Kincode App - Culture-based employee engagement platform"
                      className="absolute top-0 left-0 right-0 -z-10 object-fill w-full"
                      />
                    <img 
                      src="/assets/images/home-gradient.png"
                      alt="Kincode App - Culture-based employee engagement platform"
                      className="absolute top-0 left-0 right-0 -z-10 opacity-90 object-fill w-full"
                    />


                  {/* Main section - absolute */}
                  <div className="flex flex-col items-center justify-center absolute top-0 right-0 left-0 mt-8 lg:mt-36">
                        
                          <h1 className="text-center font-black text-white 
                          text-2xl w-3/4
                          lg:text-5xl lg:w-1/2">
                            {t('kincode.tagline')}
                          </h1>
                          <span className="text-center text-white text-sm m-4
                            hidden
                            lg:block lg:w-1/2 ">
                            {t('kincode.tagline.second')}
                          </span>
                          <button id="main-cta-button" className='cta-button z-10' onClick={(e) => handleDemoButton(e)}>
                            {t('cta')}
                          </button>
                        

                        <div className="flex flex-col items-center justify-center mt-4 lg:mt-20 
                        lg:w-1/2 md:w-1/2">
                          <img 
                              src="/assets/images/kincode-home-dashboard.png"
                              className="flex rounded-xl shadow-xl"
                              alt="Kincode App Dashboard"
                          />

                        </div>
                  </div>
                </div>

              <div className="relative lg:mt-80 lg:pt-80">

                {/* Customers section - relative */}
                <div className=" mt-80 lg:mt-80 lg:pt-96 pt-80 md:pt-96 ">
                  <Customers />
                </div>

                  {/* Key messages section - relative */}
                  <div className="flex flex-col items-center my-8 lg:px-36 lg:pt-12">
                      <h2 className="text-xl font-black p-4 text-center
                       lg:text-3xl">
                        {t('home.keyMessages.title')}
                      </h2>
                      
                      <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center 
                      space-y-4 
                      lg:space-y-0 lg:space-x-10 ">
                      {keyMessages?.map((message) => (

                        <div className="flex flex-col items-center justify-center text-center p-8 
                        lg:w-1/4">
                          <h3 className="flex flex-col items-center text-lg font-bold
                          lg:text-xl ">
                            <img className="m-4 w-[50px] h-[50px]" src={message?.imageURL}/>
                            {t(message?.title)}
                          </h3>
                          <span className="mt-8 text-sm text-gray-600">
                            {t(message?.subtitle)}
                          </span>
                        </div>
                      ))}
                      
                      </div>
                    </div>

                    {/* VSL - Video Sales Letter */}
                    <div className="flex flex-col items-center justify-center mt-20 lg:p-36 w-full">
                      <div style={{ position: "relative", paddingBottom:"55.956112852664575%", height:0, width:'100%'}}>
                          <iframe
                          title="Kincode - Video Sales Letter" 
                          src="https://www.loom.com/embed/a237713098f1480c815a4db9383e6e41?sid=63ccbc32-f084-4660-9cfb-9e03e8520bcc" 
                          frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen 
                          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                          >
                          </iframe>
                      </div>
                    </div>

                    {/* Three modules - value prop */}
                    {/* ENGAGE */}
                    <TextAndImage
                      title={t('product.engage.title')}
                      keyMessage={t('product.engage.keyMessage')}
                      keyMessageDescription={t('product.engage.keyMessage.description')}
                      bulletPoint1={t('product.engage.bullet1')} bulletPoint1Emoji="📈"
                      bulletPoint2={t('product.engage.bullet2')} bulletPoint2Emoji="🤝"
                      bulletPoint3={t('product.engage.bullet3')} bulletPoint3Emoji="🌟"
                      img="/assets/illustrations/enagement-illustration.webp"
                      alt="Kincode App - engagement platform"
                      urlLearnMore={'/product/engage/'}
                      orientation="left"
                    />

                    {/* PERFORM */}
                    <TextAndImage 
                      title={t('product.perform.title')}
                      keyMessage={t('product.perform.keyMessage')}
                      keyMessageDescription={t('product.perform.keyMessage.description')}
                      bulletPoint1={t('product.perform.bullet1')} bulletPoint1Emoji="🚀"
                      bulletPoint2={t('product.perform.bullet2')} bulletPoint2Emoji="🎯"
                      bulletPoint3={t('product.perform.bullet3')} bulletPoint3Emoji="💎"
                      img="/assets/illustrations/performance-illustration.webp"
                      alt="Kincode App - engagement platform"
                      urlLearnMore={'/product/perform/'}
                      orientation="right"
                    />


                    {/* Testimonials */}
                    <Testimonials />


                    {/* DEVELOP */}
                    <TextAndImage
                      title={t('product.develop.title')}
                      keyMessage={t('product.develop.keyMessage')}
                      keyMessageDescription={t('product.develop.keyMessage.description')}
                      bulletPoint1={t('product.develop.bullet1')} bulletPoint1Emoji="📈"
                      bulletPoint2={t('product.develop.bullet2')} bulletPoint2Emoji="🤝"
                      bulletPoint3={t('product.develop.bullet3')} bulletPoint3Emoji="🌟"
                      img="/assets/illustrations/development-illustration.webp"
                      alt="Kincode App - engagement platform"
                      urlLearnMore={'/product/develop/'}
                      orientation="left"
                    />

                    {/* BASICS */}
                    <TextAndImage
                      title={t('product.basics.title')}
                      keyMessage={t('product.basics.keyMessage')}
                      keyMessageDescription={t('product.basics.keyMessage.description')}
                      bulletPoint1={t('product.basics.bullet1')} bulletPoint1Emoji="📈"
                      bulletPoint2={t('product.basics.bullet2')} bulletPoint2Emoji="🤝"
                      bulletPoint3={t('product.basics.bullet3')} bulletPoint3Emoji="🌟"
                      img="/assets/illustrations/basics-illustration.webp"
                      alt="Kincode App - engagement platform"
                      urlLearnMore={'/product/basics/'}
                      orientation="right"
                    />
                      


                    {/* Grey Rectangle - relative */}
                    <div className="flex flex-col lg:flex-row items-center bg-gray-100 p-12 relative
                    lg:mt-20 lg:items-center lg:justify-between lg:w-full lg:pt-36 lg:pb-36">

                      <div className="flex flex-col items-start justify-center text-left
                      lg:w-1/2">
                        <h2 className="text-xl lg:text-3xl text-center lg:text-left font-bold p-4">
                          {t('home.keyRectangle.title')}
                        </h2>

                        <ul className="list-disc space-y-8 p-4 text-sm m-2">
                          <li>
                            <span className=" ">
                              <b>{t('home.keyRectangle.bullet-1.title')}</b>: {t('home.keyRectangle.bullet-1.description')}
                            </span>
                          </li>
                          <li>
                            <span className=" ">
                              <b>{t('home.keyRectangle.bullet-2.title')}</b>: {t('home.keyRectangle.bullet-2.description')}
                            </span>
                          </li>
                          <li>
                            <span className=" ">
                              <b>{t('home.keyRectangle.bullet-3.title')}</b>: {t('home.keyRectangle.bullet-3.description')}
                            </span>
                          </li>
                          <li>
                            <span className=" ">
                              <b>{t('home.keyRectangle.bullet-4.title')}</b>: {t('home.keyRectangle.bullet-4.description')}
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div className="">
                        <img 
                        src="https://www.dropbox.com/scl/fi/nkilssnnqjbgruw5p1cdo/Kincode-Dashboard.png?rlkey=1h8m470dqw70x7ga3fovxnsis&raw=1"
                        atl="Kincode App Dashboard"
                        className="rounded-2xl" />
                      </div>

                    </div>

                  {/* HIDDEN - Pricing section */}
                  <div className="hidden flex flex-col my-20 lg:my-24  ">
                      {/* <PricingWidget /> */}
                  </div>


                  {/* Video section */}
                  <div className="hidden flex flex-col items-center justify-center mt-20 lg:p-36">
                  {/* linear-gradient(264deg, rgba(80, 227, 194, 0.87) 12%, rgba(102, 118.73, 255, 0.87) 47%, rgba(255, 129.44, 72.25, 0.87) 100%) */}
  
                      <h2 className="absolute z-10 h-[60vh] lg:h-[40vh] text-2xl font-bold p-8">
                        {t('home.video.title')}
                      </h2>
                      <span className="absolute z-10 h-[70vh] lg:h-[10vh] text-sm text-gray-600">
                        {t('home.video.subtitle')}
                      </span>
                      <img
                      src="https://www.dropbox.com/scl/fi/t7xoi7gz9bw9wk0yda2gw/Rectangle-16.png?rlkey=cvcqa34upk0i10azig0tw7g1s&raw=1"
                      className=" object-fill"
                      />
                      <div class="absolute z-0 opacity-80 bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% h-[60vh] lg:h-[53vh] w-full" />
                  </div>

                  {/* CTA */}
                  < CTA />
        </div>

        <Footer />
    </React.Fragment>
  );
}

export default App;
