// React
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

// Common
import Footer from '../common/footer';
import Header from '../common/newHeader';

// 3P
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

function TermsAndConditions () {
  const location = useLocation();
  const {t} = useTranslation();


// ------------------------------------------------------------------------------------------
// Functions
// ------------------------------------------------------------------------------------------
useEffect (() => {
  // Send pageview with a custom path
  ReactGA.send({ hitType: "pageview", page: location.pathname, title: "T&Cs" });
          
  // Scroll to top
  window.scrollTo(0, 0);
  }, []);


// ------------------------------------------------------------------------------------------
// Render function
// ------------------------------------------------------------------------------------------
    return (
  <React.Fragment>
    <Header />
    <div className="flex flex-col md:flex-row items-start 
      bg-white justify-center 
      p-6 ml-2 mr-2 mt-20 mb-2
      md:p-8 md:ml-12 md:mr-12 md:mt-20 md:mb-4
    dark:text-white dark:bg-green-800">
      <div className="md:ml-6 md:mr-6 flex flex-col items-start w-full justify-between">
        <div>
          <h1 className="font-light text-2xl text-bold mb-6">
            Terms and Conditions
          </h1>
        </div>
        <div className="text-justify w-full">
          <span className="italic font-bold mb-2">The agreement</span>
          <br></br>
          <span className="mb-6">
            The use of this website and services on this website provided by
            Kincode (hereinafter referred to as "Website") are subject to the
            following Terms & Conditions, all parts, and sub-parts of which are
            specifically incorporated by reference here. This Agreement shall
            govern the use of all pages on this website (hereinafter
            collectively referred to as "Website") and any services provided by
            or on this Website ("Services").
          </span>
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">Definitions</span>
          <ul className="list-disc ml-6">
            <li>
              “Agreement” denotes this Terms and Conditions and the Privacy
              Policy and other documents provided to you by the Website.
            </li>
            <li>“We,” “us,” and “our” are references to KINCODE.APP.</li>
            <li>
              “User,” “You,” and “your” denotes the person who is accessing the
              website for taking or availing of any service from us. User shall
              include the company, partnership, sole trader, person, body
              corporate, or association taking services of this Website.
            </li>
            <li>
              ”Website” shall mean and include kincode.app and any successor
              Website of the Company or any of its affiliates.
            </li>
            <li>
              Parties: Collectively, the parties to this Agreement (We and You)
              will be referred to as Parties.
            </li>
          </ul>
          <br></br>
          <span className="italic font-bold mb-2">Assent and Acceptance</span>
          <br></br>
          PLEASE READ THESE TERMS OF USE, OUR PRIVACY POLICY, AND ALL APPLICABLE
          SUPPLEMENTAL TERMS (COLLECTIVELY, THE "TERMS") CAREFULLY, AS THEY
          CONTAIN TERMS AND CONDITIONS THAT IMPACT YOUR RIGHTS, OBLIGATIONS, AND
          REMEDIES IN CONNECTION WITH YOUR USE OF THE SERVICES AND CONTENT. FOR
          EXAMPLE, THE TERMS INCLUDE:
          <ul className="list-disc mt-6 ml-6 mb-6">
            <li>
              YOUR OBLIGATION TO COMPLY WITH ALL APPLICABLE LAWS AND
              REGULATIONS.
            </li>
            <li>LIMITATIONS OF OUR LIABILITY TO YOU; AND</li>
            <li>
              A REQUIREMENT THAT YOU PURSUE CLAIMS OR SEEK RELIEF AGAINST US
              (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN
              INDIVIDUAL BASIS, RATHER THAN AS A PARTICIPANT IN ANY CLASS OR
              REPRESENTATIVE ACTION OR PROCEEDING.
            </li>
          </ul>
          YOUR ACCESS TO AND USE OF THE SERVICES ARE CONDITIONED ON YOUR
          ACCEPTANCE OF AND COMPLIANCE WITH ALL APPLICABLE TERMS. If you do not
          agree to these Terms or our Privacy Policy, then please cease using
          the Services immediately. We reserve the right to change these Terms
          at any time (see “Changes to these Terms” below.) By accessing,
          browsing, and/or using the Services after updates to these Terms have
          been posted, you agree to be bound by the updated Terms. THESE TERMS
          AND OUR PRIVACY POLICY CONSTITUTE A BINDING AGREEMENT BETWEEN YOU AND
          KINCODE.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">
            Consequences of Non-Compliance
          </span>
          <br></br>
          Your failure to comply with the Terms may result in the suspension or
          termination of your account and/or access to the Services and may
          subject you to civil and criminal penalties.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">Age restriction</span>
          <br></br>
          You must be at least 13 (Thirteen) years of age to use this Website,
          or any Services contained herein. By using this Website, you represent
          and warrant that you are at least 13 years of age and may legally
          agree to this Agreement. We assume no responsibility or liability for
          any misrepresentation of your age.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">Accuracy of information</span>
          <br></br>
          Occasionally there may be information on the Website that contains
          typographical errors, inaccuracies, or omissions that may relate to
          user descriptions, pricing, availability, promotions, and offers. We
          reserve the right, in our sole discretion, to make changes or
          modifications to these Terms and conditions at any time and for any
          reason. We undertake no obligation to update, amend or clarify
          information on the Website, including, without limitation, pricing
          information, except as required by law. No specified update or refresh
          date applied on the Website should be taken to indicate that all
          information on the Website or Services has been modified or updated.
          Please ensure that you check the applicable Terms every time you use
          our site so that you understand which Terms apply. You will be subject
          to and will be deemed to have been made aware of and to have accepted
          the changes in any revised Terms and conditions by your continued use
          of the site after the date such revised Terms and conditions are
          posted.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">Advertisements</span>
          <br></br>
          During your use of the Website and Services, you may enter
          correspondence with or participate in promotions of advertisers or
          sponsors showing their goods or services through the Website and
          Services. Any such activity, and any terms, conditions, warranties, or
          representations associated with such activity, are solely between you
          and the applicable third party. We shall have no liability,
          obligation, or responsibility for any such correspondence, purchase,
          or promotion between you and any such third party.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">User obligations</span>
          <br></br>
          As a user of the Website or Services, you may be asked to register
          with us. When you do so, you will choose a user identifier, which may
          be your email address or another term, as well as a password. You may
          also provide personal information, including, but not limited to, your
          name. You are responsible for ensuring the accuracy of this
          information. This identifying information will enable you to use the
          Website and Services. You must not share such identifying information
          with any third party, and if you discover that your identifying
          information has been compromised, you agree to notify us immediately
          in writing. An email notification will suffice. You are responsible
          for maintaining the safety and security of your identifying
          information, as well as keeping us apprised of any changes to your
          identifying information. Providing false or inaccurate information or
          using the Website or Services to further fraud or unlawful activity is
          grounds for immediate termination of this Agreement.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">User content</span>
          <br></br>
          Content Responsibility.
          <br></br>
          The website permits you to share content, post comments, feedback,
          etc., but you are solely responsible for the content posted by you.
          You represent that you have required permission to use the content.
          <br></br>
          When posting content to the website, please do not post content that:
          <ul className="list-disc mt-6 ml-6 mb-6">
            <li>
              Contains ill-mannered, profane, abusive, racist, or hateful
              language or expressions, text, photographs, or illustrations that
              are pornographic or in poor taste, inflammatory attacks of a
              personal, racial or religious nature.
            </li>
            <li>
              It is defamatory, threatening, disparaging, grossly inflammatory,
              false, misleading, fraudulent, inaccurate, unfair, and contains
              exaggeration or unsubstantiated claims.
            </li>
            <li>
              Violating the privacy rights of any third party is unreasonably
              harmful or offensive to any individual or community.
            </li>
            <li>
              Discriminates on the grounds of race, religion, national origin,
              gender, age, marital status, sexual orientation, or disability, or
              refers to such matters in any manner prohibited by law.
            </li>
            <li>
              Violates or inappropriately encourages the violation of any
              municipal, state, federal, or international law, rule, regulation,
              or ordinance.
            </li>
            <li>
              Uses or attempts to use another's account, password, service, or
              system except as expressly permitted by the Terms of use uploads
              or transmits viruses or other harmful, disruptive, or destructive
              files.
            </li>
            <li>
              Sends repeated messages related to another user and/or makes
              derogatory or offensive comments about another individual or
              repeats prior posting of the same message under multiple emails or
              subjects.
            </li>
            <li>
              Any submitted content that includes, but is not limited to the
              following, will be refused. If repeated violations occur, we
              reserve the right to cancel user access to the website without
              advanced notice.
            </li>
          </ul>
          <span className="italic font-bold mb-2">Iframes</span>
          <br></br>
          Without prior approval and express written permission, you may not
          create frames around our Web pages or use other techniques that alter
          in any way the visual presentation or appearance of our Web site.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">Acceptable use</span>
          <br></br>
          You agree not to use the Website or Services for any unlawful purpose,
          or any purpose prohibited under this clause. You agree not to use the
          Website or Services in any way that could damage the Website,
          Services, or general business of Kincode. You further agree not to use
          the Website or Services:
          <ul className="list-disc mt-6 ml-6 mb-6">
            <li>
              To harass, abuse, or threaten others or otherwise violate any
              person's legal rights.
            </li>
            <li>
              To violate any of our intellectual property rights or any third
              party.
            </li>
            <li>
              To upload or otherwise disseminate any computer viruses or other
              software that may damage the property of another.
            </li>
            <li>To perpetrate any fraud.</li>
            <li>
              To engage in or create any unlawful gambling, sweepstakes, or
              pyramid scheme.
            </li>
            <li>
              To publish or distribute any obscene or defamatory material.
            </li>
            <li>
              To publish or distribute any material that incites violence, hate,
              or discrimination towards any group.
            </li>
            <li>To unlawfully gather information about others.</li>
          </ul>
          <span className="italic font-bold mb-2">
            Hyperlinking to our content
          </span>
          <br></br>
          Without prior written approval, the following organizations may
          link to our site:
          <ul className="list-disc mt-6 ml-6 mb-6">
          <li>Government agencies.</li>
          <li>Search engines.</li>
          <li>News organizations.</li>
          <li>Online directory distributors, when they list us in
          the directory, may link to our Web site in the same manner as they
          hyperlink to the Web sites of other listed businesses; and </li>
          <li> Systemwide Accredited Businesses except soliciting non-profit
          organizations, charity shopping malls, and charity fundraising groups
          that may not hyperlink to our Web site.
          </li>
          </ul>
          We allow these links if the
          link: 
          <ul className="list-disc mt-6 ml-6 mb-6">
            <li>(a) is not misleading;</li> 
            <li>(b) does not falsely imply sponsorship,
          endorsement, or approval of the linking party or its products or
          services; and
          </li>
          <li> (c) is appropriate to the linking party's site.
          </li>
          </ul>
          
          We may consider and approve in our sole discretion other link requests
          from the following types of organizations:
          <ul className="list-disc mt-6 ml-6 mb-6">
           <li>Commonly-known consumer and business information sources such as
            Chambers of Commerce and Consumers Union.
            </li>
            <li>Dot.com community sites.
            </li>
            <li>Associations or other groups representing charities, including
            charity giving sites.
            </li>
            <li>Online directory distributors.
            </li>
            <li>Internet
            portals.
            </li>
            <li>Accounting, law, and consulting firms whose primary
            clients are businesses; and </li>
            <li>Educational institutions and trade
            associations.</li>
          </ul>
          We will approve link requests from these organizations if we determine that: 
          <ul className="list-disc mt-6 ml-6 mb-6">
          <li>(a) the link will not reflect unfavorably on us or our accredited businesses 
            (for example, trade associations or other organizations representing inherently suspect types of business,
             such as work-at-home opportunities, are not permitted to link).
             </li>
             <li>(b) the organization does not have an unsatisfactory record with us.
             </li>
             <li>(c) the benefit to us from the increased visibility associated with the hyperlink outweighs the cost of the link.
             </li>
             <li> (d) is not misleading.
             </li>
             <li> (e) does not falsely imply sponsorship,
          endorsement, or approval of the linking party or its products or
          services; and </li> 
          <li>(f) is appropriate to the linking party's website.</li>
           </ul>
           If
          you are one of the organizations listed in paragraph 2 and would like
          to link to our website, please contact us. List any URLs from which
          you intend to link to our Web site, along with the URL(s) on our site
          to which you would like to link. Response time is 2-3 weeks. 
          <br></br>
          Those
          approved may link to our site by using our company name,
          the URL (web address) being linked to, or any other description of our
          website or material that makes sense within the context and format of
          the linking party's site. No use of the Kincode logo or other artwork
          will be allowed for linking absent a trademark license agreement.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">Intellectual property</span>
          <br></br>
          You agree that the Website and all Services provided by us are the
          property of Kincode, including all copyrights, trademarks, trade
          secrets, patents, and other intellectual property ("Our IP"). You
          agree that we own all rights, title, and interest in and to the Our IP
          and that you will not use Our IP for any unlawful or infringing
          purpose. You agree not to reproduce or distribute Our IP in any way,
          including electronically or via registration of any new trademarks,
          trade names, service marks, or Uniform Resource Locators (URLs),
          without express written permission from us.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">Indemnification</span>
          <br></br>
          To the maximum extent permitted by law, you agree to indemnify, defend
          and hold harmless Kincode and its affiliates and/or related entities,
          whether direct or indirect, current, former or future, and its and
          their respective current, former, or future officers, directors,
          employees, agents, successors and assigns and related third parties
          (each an “Indemnified Party”), for any claims, causes of action,
          debts, damages, losses, costs, liabilities and expenses (including
          reasonable attorneys’ fees) relating to or arising out of any
          third-party claim that (a) your use of or inability to use the
          Services, (b) any user postings made by you, (c) your violation of any
          terms of this Agreement or your violation of any rights of a
          third-party, or (d) your violation of any applicable laws, rules or
          regulations, except to the extent caused by any unlawful or negligent
          act or omission by Kincode. Kincode reserves the right, at its own
          cost, to assume the exclusive defense and control of any matter
          otherwise subject to indemnification by you, in which event you will
          fully cooperate with Kincode in asserting any available defenses. An
          Indemnified Party may participate in the defense by counsel of its own
          choosing, at its own cost and expense. You shall not settle any claim
          that adversely affects an Indemnified Party or imposes any obligation
          or liability on an Indemnified Party without the Indemnified Party’s
          prior written consent.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">Exclusion of liability</span>
          <br></br>
          You understand and agree that we (A) do not guarantee the accuracy,
          completeness, validity, or timeliness of information listed by us or
          any third parties, and (B) shall not be responsible for any materials
          posted by us or any third party. You shall use your judgment, caution,
          and common sense in evaluating any prospective methods or offers and
          any information provided by us or any third party. Further, we shall
          not be liable for direct, indirect consequential, or any other form of
          loss or damage that may be suffered by a user using the platform,
          including loss of data or information or any kind of financial or
          physical loss or damage. In no event shall Kincode, nor its Owner,
          directors, employees, partners, agents, suppliers, or affiliates, be
          accountable for any indirect, incidental, special, eventful, or
          exemplary costs, including without limitation, loss of proceeds,
          figures, usage, goodwill, or other intangible losses, consequential
          from (i) your use or access of or failure to access or use the
          service; (ii) any conduct or content of any third party on the
          service; (iii) any content attained from the service; and (iv)
          unlawful access, use or alteration of your transmissions or content,
          whether or not based on guarantee, agreement, domestic wrong
          (including carelessness) or any other lawful concept, whether or not
          we've been aware of the possibility of such damage, and even if a cure
          set forth herein is originated to have futile of its important
          purpose.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">
            Limitation on liabilities
          </span>
          <br></br>
          We are not liable for any damages that may occur to you because of
          your use of the Website or Services to the fullest extent permitted by
          law. This section applies to any claims by you, including, but not
          limited to, lost profits or revenues, consequential or punitive
          damages, negligence, strict liability, fraud, or torts of any kind.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">
            Modification and variation
          </span>
          <br></br>
          We may, from time to time and at any time without notice to you,
          modify this Agreement. You agree that we have the right to modify this
          Agreement or revise anything contained herein. You further agree that
          all modifications to this Agreement are in full force and effect
          immediately upon posting on the Website and that modifications or
          variations will replace any prior version of this Agreement unless
          prior versions are specifically referred to or incorporated into the
          latest modification or variation of this Agreement.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">No warranties</span>
          <br></br>
          You agree that your use of the Website and Services is at your sole
          and exclusive risk and that any Services provided by us are on an "As
          Is" basis. We hereby expressly disclaim any express or implied
          warranties of any kind, including but not limited to the implied
          warranty of fitness for a particular purpose and the implied warranty
          of merchantability. We make no warranties that the Website or Services
          will meet your needs or that the Website or Services will be
          uninterrupted, error-free, or secure. We also make no warranties as to
          the reliability or accuracy of any information on the Website or
          obtained through the Services. You agree that any damage that may
          occur to you through your computer system or because of the loss of
          your data from your use of the Website or Services is your sole
          responsibility and that we are not liable for any such damage or loss.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">
            Governing law and jurisdiction
          </span>
          <br></br>
          This website originates from Spain. The laws of Spain. Without regard
          to its conflict of law, principles will govern these terms to the
          contrary. You hereby agree that all disputes arising out of or in
          connection with these terms shall be submitted to the exclusive
          jurisdiction of Spain. By using this website, you consent to the
          jurisdiction and venue of such courts in connection with any action,
          suit, proceeding, or claim arising under or by reason of these terms.
          You hereby waive any right to trial by jury arising out of these
          terms.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">Severability</span>
          <br></br>
          If any provision of these Terms and conditions is determined to be
          unlawful, void, or unenforceable, such provision shall nonetheless be
          enforceable to the fullest extent permitted by applicable law, and the
          unenforceable portion shall be deemed to be severed from these Terms
          and conditions, such determination shall not affect the validity and
          enforceability of any other remaining provisions.
          <br></br>
          <br></br>
          <span className="italic font-bold mb-2">Contacting us</span>
          <br></br>
          If you would like to contact us to understand more about terms or wish
          to contact us concerning any matter, you may do so via the contact us
          or email us. This document was last updated on August 22, 2022
        </div>
      </div>
    </div>
    <Footer />
  </React.Fragment>
  );
}
export default TermsAndConditions;