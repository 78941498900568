
// React 
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

// 3P
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

// Common
import Footer from '../common/footer';
import Header from '../common/newHeader';


function Roadmap () {
    // ------------------------------------------------------------------------------------------
    // State variables
    const location = useLocation();
    const {t} = useTranslation();


    // ------------------------------------------------------------------------------------------
    // Functions
    // ------------------------------------------------------------------------------------------
    useEffect (() => {
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Roadmap" });
        
        // Scroll to top
        window.scrollTo(0, 0);

      }, []);
    
    // ------------------------------------------------------------------------------------------
    // Rendering functions
    // ------------------------------------------------------------------------------------------
    return(
    <React.Fragment>
        <Header />

        <div className='flex flex-col items-center m-8 mt-32 lg:m-40 mb-0'>
            {/* Header */}
            <div className='flex flex-col items-start text-left w-full space-y-4 mb-20'>
                <h1 className='flex uppercase text-xs font-medium'> {t('roadmap.title')}</h1>
                <h2 className='flex text-4xl font-bold'>{t('roadmap.subtitle')} </h2>            
                <span className='flex text-gray-800 text-lg'>
                    {t('roadmap.title.description')}
                </span>
                <span className='flex text-gray-600 text-sm'>
                    {t('roadmap.title.description.last-updated') + " "}9/05/2024
                </span>
            </div>

            {/* List of initiatives */}
            {/* ETA - 1 */}
            <div className='flex flex-col lg:flex-row lg:items-start w-full space-y-2 lg:space-y-0 lg:space-x-8 mb-20'>
                {/* ETA */}
                <div className='flex flex-col items-start lg:w-1/4 uppercase text-2xl font-bold'>
                    Q2 2024
                </div>


                {/* Initiative */}
                <div className='flex flex-col items-start lg:w-3/4 text-xl font-medium space-y-4 text-left'>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-400 px-3 py-2 rounded mr-4 text-sm w-1/8'> WIP </span>
                        Pulse: new iOS and Windows app for daily pulse questions.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-400 px-3 py-2 rounded mr-4 text-sm w-1/8'> WIP </span>
                        Time-off: Allow managers (i) to award additional days off, and (ii) a team calendar detailed view to understand coverage. 
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-400 px-3 py-2 rounded mr-4 text-sm w-1/8'> WIP </span>
                        Workforce self management: allow HR to change manager (with all its implications) and username / e-mail.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-gray-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Not started </span>
                        Pulse: Response rate KPIs for each Question and Campaign/Survey.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-gray-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Not started </span>
                        Pulse: Dive deep into each Question.
                    </div>
                </div>
            </div>
            

            {/* ETA - 3 */}
            <div className='flex flex-col lg:flex-row lg:items-start w-full space-y-2 lg:space-y-0 lg:space-x-8 mb-20'>
                {/* ETA */}
                <div className='flex flex-col items-start lg:w-1/4 uppercase text-2xl font-bold'>
                    Recent Launches
                </div>


                {/* Initiative */}
                <div className='flex flex-col items-start lg:w-3/4 text-xl font-medium space-y-4 text-left'>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Launched </span>
                        Career planning module: clarify career paths for employees, manage promotions.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Launched </span>
                        Recognition: Monthly newsletter and platform leaderboards for top recognisers and recognised employees.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Launched </span>
                        Punch-in: Punch in and out of work.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Launched </span>
                        Time-off: Request days-off and review your time off balance.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Launched </span>
                        Pulse: Define your own KPIs based on specific questions.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Launched </span>
                        Workforce self management: allow HR to change manager (with all its implications) and username / e-mail.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                    <span className='bg-green-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Launched </span>
                        1:1s HR dashboard: compliance dashboard, to dive deep into each team's compliance.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Launched </span>
                        Onboarding module: ramp up your new joiners faster.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Launched </span>
                        Workforce self management: allow HR to create, and mark employees as inactive.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Launched </span>
                        1:1 improvements: manager of manager visibility.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Launched </span>
                        1:1 improvements: private notes for managers: attrition risk and manager-only notes.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-green-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Launched </span>
                        Performance Review reporting: dashboard to dive deep into each performance review campaign results.
                    </div>
                </div>
            </div>

            {/* ETA - 2 */}
            <div className='flex flex-col lg:flex-row lg:items-start w-full space-y-2 lg:space-y-0 lg:space-x-8 mb-20'>
                {/* ETA */}
                <div className='flex flex-col items-start lg:w-1/4 uppercase text-2xl font-bold'>
                    Future (Q3 2024)
                </div>


                {/* Initiative */}
                <div className='flex flex-col items-start lg:w-3/4 text-xl font-medium space-y-4 text-left'>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-gray-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Not started </span>
                        Recruitment module: interview processes and candidate management.
                    </div>
                    <div className='flex flex-row items-center w-full border-b-2 pb-4'>
                        <span className='bg-gray-200 px-3 py-2 rounded mr-4 text-sm w-1/8'> Not started </span>
                        Goals and OKRs module: define and track goals.
                    </div>
                </div>
            </div>

            
        </div>


        <Footer />

    </React.Fragment>
);

}

export default Roadmap;