// React
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

// Common
import Header from '../../common/newHeader';
import Footer from '../../common/footer';

// 3P
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

// Widgets
import Testimonials from '../../widgets/testimonials';
import CTA from '../../widgets/cta';
import CustomersWidget from '../../widgets/customers';
import TextAndImage from '../../widgets/textAndimage';

function Perform () {
  const location = useLocation();
  const {t} = useTranslation();


// ------------------------------------------------------------------------------------------
// Functions
// ------------------------------------------------------------------------------------------
useEffect (() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Product-Perform" });

    // Scroll to top
    window.scrollTo(0, 0);
  
  }, []);


  // ------------------------------------------------------------------------------------------
  // Render function
  // ------------------------------------------------------------------------------------------
  return(
      <React.Fragment>
        <Header/>

        <div className='flex flex-col items-center h-auto w-auto'>
            {/* Headline */}
            <div id="main" className='flex flex-col space-y-12 lg:space-y-0 lg:flex-row items-center lg:justify-center w-full px-8 lg:px-40 py-24 bg-indigo-50'>
                {/* Text left-side */}
                <div className='flex flex-col items-start w-full lg:w-1/3 space-y-4'>
                    {/* Section title */}
                    <h1 className='text-left text-lg font-bold text-[#63C7AA]'>{t('product.perform.title')}</h1>
                    {/*  Section description */}

                    {/* Section key message */}
                    <h2 className='widget-keyMessage-title'>{t('product.perform.keyMessage')}</h2>

                    {/* Section description */}
                    <p className='widget-keyMessage-description'>{t('product.perform.keyMessage.description')}</p>

                    {/* Section key bullet points */}
                    <div className='flex flex-col items-start text-left space-y-4 justify-between w-full pt-4'>
                        <div className='widget-bulletPoints'><span className='mr-4'>🚀</span>{t('product.perform.bullet1')}</div>
                        <div className='widget-bulletPoints'><span className='mr-4'>🎯</span>{t('product.perform.bullet2')}</div>
                        <div className='widget-bulletPoints'><span className='mr-4'>💎</span>{t('product.perform.bullet3')}</div>
                    </div>


                </div>

                {/* Image right side */}
                <div className='flex flex-col items-center justify-center w-full lg:w-2/3 pt-8'>
                    <div className='container content-fit mx-auto lg:w-2/3 max-w-sm lg:max-w-xl overflow-hidden'>
                        <img className='rounded-lg object-cover w-full h-auto shadow rounded-lg' 
                            alt="Kincode App - engagement platform " 
                            src="/assets/illustrations/performance-illustration.webp" />
                    </div>
                </div>


            </div>

            {/* Customers */}
            <CustomersWidget />

            {/* Performance Reviews */}
            <TextAndImage 
                title={t('home.pricing.feature.performance')}
                keyMessage={t('product.performanceReview.keyMessage')}
                keyMessageDescription={t('product.performanceReview.keyMessage.description')}
                bulletPoint1={t('product.performanceReview.bullet1')} bulletPoint1Emoji="🔄"
                bulletPoint2={t('product.performanceReview.bullet2')} bulletPoint2Emoji="🔍"
                bulletPoint3={t('product.performanceReview.bullet3')} bulletPoint3Emoji="🌱"
                img="/assets/illustrations/performance/performance-reviews-illustration-useful.webp"
                alt="Kincode App - engagement platform" 
                urlLearnMore={'/product/perform/performance/'}
                orientation="left"
            />

            {/* Testimonials  */}
            <Testimonials />

            {/* Calibration */}
            <TextAndImage
                title={t('home.pricing.feature.calibration')}
                keyMessage={t('product.calibration.keyMessage')}
                keyMessageDescription={t('product.calibration.keyMessage.description')}
                bulletPoint1={t('product.calibration.bullet1')} bulletPoint1Emoji="🏢"
                bulletPoint2={t('product.calibration.bullet2')} bulletPoint2Emoji="📊"
                bulletPoint3={t('product.calibration.bullet3')} bulletPoint3Emoji="🌱"
                img="/assets/illustrations/calibration/performance-calibration-illustration.webp"
                alt="Kincode App - engagement platform" 
                urlLearnMore={'/product/perform/calibration/'}
                orientation="right"
            />

            {/* Goals and OKRs */}
            {/* <TextAndImage
                title={t('home.pricing.feature.goals')}
                keyMessage={t('product.goals.keyMessage')}
                keyMessageDescription={t('product.goals.keyMessage.description')}
                bulletPoint1={t('product.goals.bullet1')} bulletPoint1Emoji="🎯"
                bulletPoint2={t('product.goals.bullet2')} bulletPoint2Emoji="🛠️"
                bulletPoint3={t('product.goals.bullet3')} bulletPoint3Emoji="⏰"
                img="/assets/illustrations/meetings-ilustration.webp"
                alt="Kincode App - engagement platform" 
                urlLearnMore={'/product/perform/goals/'}
                orientation="right"
            /> */}

            {/* Book a demo - or free trial CTA */}
            <CTA />

        </div>
        
        
        <Footer/>

      </React.Fragment>
  );

}

export default Perform;