// React
import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import AppearingImage from './appearingImage';


// ------------------------------------------------------------------------------------------
// Widget
// ------------------------------------------------------------------------------------------
const TextAndImage = ({
    orientation = "left", // defines where the image is placed
    title, 
    keyMessage, 
    keyMessageDescription,
    bulletPoint1, bulletPoint1Emoji,
    bulletPoint2, bulletPoint2Emoji,
    bulletPoint3, bulletPoint3Emoji,
    img,
    alt,
    urlLearnMore
}) => {

    const {t} = useTranslation();


    // ------------------------------------------------------------------------------------------
    // Rendering
    // ------------------------------------------------------------------------------------------
    if (orientation === "left") {
        return (
            <div className='widget-container'>
                <div className='flex flex-col space-y-12 lg:space-y-0 lg:flex-row items-center justify-center w-full '>
                    {/* Text left-side */}
                    <div className='flex flex-col items-start w-full lg:w-1/2 space-y-4'>
                        {/* Section title */}
                        <h1 className='text-left text-lg font-bold text-[#63C7AA]'>{title}</h1>
                        {/*  Section description */}

                        {/* Section key message */}
                        <h2 className='widget-keyMessage-title'>{keyMessage}</h2>

                        {/* Section description */}
                        <p className='widget-keyMessage-description'>{keyMessageDescription}</p>

                        {/* Section key bullet points */}
                        <div className='flex flex-col items-start text-left space-y-4 justify-between w-full pt-4'>
                                <div className='widget-bulletPoints'><span className='mr-4'>{bulletPoint1Emoji}</span>{bulletPoint1}</div>
                                <div className='widget-bulletPoints'><span className='mr-4'>{bulletPoint2Emoji}</span>{bulletPoint2}</div>
                                <div className='widget-bulletPoints'><span className='mr-4'>{bulletPoint3Emoji}</span>{bulletPoint3}</div>
                        </div>

                        {/* CTA - Learn more */}
                        { urlLearnMore &&
                            <div className='cta-learn-more'>
                                <Link to={urlLearnMore}>
                                    <div className='pt-4'>
                                        {t('learn-more')}
                                        <span aria-hidden="true">→</span>
                                    </div>
                                </Link>
                            </div>  
                        }

                    </div>

                    {/* Image right side */}
                    <div className='flex flex-col items-center justify-center w-full lg:w-2/3 pt-8'>
                        <div className='container content-fit mx-auto lg:w-2/3 max-w-sm lg:max-w-xl '>
                                <AppearingImage img={img} alt={alt} />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='widget-container '>
                <div className='flex flex-col space-y-12 lg:space-y-0 lg:flex-row items-center justify-center w-full '>

                    {/* Image left side */}
                    <div className='hidden lg:block flex flex-col items-center justify-center w-full lg:w-2/3 pt-8'>
                        <div className='container content-fit mx-auto lg:w-2/3 max-w-sm lg:max-w-xl'>
                            <AppearingImage img={img} alt={alt} />
                        </div>
                    </div>
                    

                    {/* Text right side */}
                    <div className='flex flex-col items-start w-full lg:w-1/2 space-y-4'>
                        {/* Section title */}
                        <h1 className='text-left text-lg font-bold text-[#63C7AA]'>{title}</h1>
                        {/*  Section description */}

                        {/* Section key message */}
                        <h2 className='widget-keyMessage-title'>{keyMessage}</h2>

                        {/* Section description */}
                        <p className='widget-keyMessage-description'>{keyMessageDescription}</p>

                        {/* Section key bullet points */}
                        <div className='flex flex-col items-start text-left space-y-4 justify-between w-full pt-4'>
                                <div className='widget-bulletPoints'><span className='mr-4'>{bulletPoint1Emoji}</span>{bulletPoint1}</div>
                                <div className='widget-bulletPoints'><span className='mr-4'>{bulletPoint2Emoji}</span>{bulletPoint2}</div>
                                <div className='widget-bulletPoints'><span className='mr-4'>{bulletPoint3Emoji}</span>{bulletPoint3}</div>
                        </div>

                        {/* CTA - Learn more */}
                        { urlLearnMore &&
                            <div className='cta-learn-more'>
                                <Link to={urlLearnMore} >
                                <div className='pt-4'>
                                        {t('learn-more')}
                                        <span aria-hidden="true">→</span>
                                    </div>
                                </Link>
                            </div>  
                        }

                    </div>

                    {/* Image below for mobile */}
                    <div className='block lg:hidden flex flex-col items-center justify-center w-full lg:w-2/3 pt-8'>
                        <div className='container content-fit mx-auto lg:w-2/3 max-w-sm lg:max-w-xl'>
                            <AppearingImage img={img} alt={alt} />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}


export default TextAndImage;