// Create a simple testimonials widget that displays a large quote, and the name of the person who said it. 
// The widget should be able to display multiple testimonials, and cycle through them every 5 seconds.
// The widget should be able to display the testimonials in a random order.

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { testimonials } from '../data/testimonials';

import Configs from '../Configs';

function Testimonials() {
  const {t} = useTranslation();
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  const [currentTestimonial, setCurrentTestimonial] = useState(testimonials[currentTestimonialIndex]);

  useEffect(() => {
    // Timer to change the testimonial every 5 seconds
    const interval = setInterval(() => {
      // Update index
      const index = (currentTestimonialIndex + 1) % testimonials?.length;
      setCurrentTestimonialIndex(index);
      // Update testimonial
      const currentTestimonialAux = {...testimonials[index]};
      setCurrentTestimonial(currentTestimonialAux);

      if (Configs.devEnvironment) console.log("[testimonials.js][useEffect()] - currentTestimonialIndex: ", currentTestimonialIndex, index, currentTestimonial, currentTestimonialAux);

    }, 10000);

    return () => clearInterval(interval);
  }, [currentTestimonial]);

  // ------------------------------------------------------------------------------------------
  // Functions
  // ------------------------------------------------------------------------------------------

  const nextTestimonial = () => {
    setCurrentTestimonial((currentTestimonial + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentTestimonial(
      (currentTestimonial - 1 + testimonials.length) % testimonials.length
    );
  };


  // ------------------------------------------------------------------------------------------
  // Render
  // ------------------------------------------------------------------------------------------

return (
    <div className='w-full'>
      <div className=' widget-container w-1/2 bg-violet-100'>
          {/* <h1 className='text-left text-lg font-bold text-[#63C7AA]'>{t('testimonials.title')}</h1> */}
          <div className='flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-8'>
            {/* Image */}
              {/* POC */}
              <div className='w-1/6 h-1/6 p-2 rounded-full bg-violet-200'>
                <img className="content-fit rounded-full overflow-hidden" src={currentTestimonial?.img} alt={t(currentTestimonial?.name)} />
              </div>
            
              {/* Text */}
            <div className='flex flex-col items-center w-full space-y-4 '>
                <blockquote className='text-left text-base lg:text-lg font-normal text-gray-600 italic'>"{t(currentTestimonial?.quote)}"</blockquote>
                <cite className='flex flex-row space-x-4  items-center text-left text-sm font-normal text-gray-600'>{t(currentTestimonial?.name)} 
                    {/* Company image */}
                    <div className='w-1/6 h-1/6 rounded-full ml-4'>
                      <img className="content-fit grayscale" src={currentTestimonial?.companyImg} alt={t(currentTestimonial?.name)} />
                    </div>
                </cite>
            </div>
          </div>
      </div>
    </div>
);
}

export default Testimonials;