// React
import React, { useEffect } from "react";
import {useLocation, Link} from "react-router-dom";

// Header & Footer
import Header from '../common/newHeader';
import Footer from '../common/footer';
import Bot from "../common/bot";

// 3P
import ReactGA from 'react-ga4';

// Sections within Landing Page
import { useTranslation } from "react-i18next";

function Partners() {
// ------------------------------------------------------------------------------------------

  const {t} = useTranslation();
  const location = useLocation();

// ------------------------------------------------------------------------------------------
// Functions
// ------------------------------------------------------------------------------------------
useEffect (() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Partners" });

    // Scroll to top
    window.scrollTo(0, 0);
    
    }, []);

// ------------------------------------------------------------------------------------------
// Rendering functions
// ------------------------------------------------------------------------------------------
    return (
        <React.Fragment>
            <Header />

            <Bot />

            <div className="flex flex-col mt-20 mb-20 space-y-24 mx-auto">

                {/* Section 1 - Join the partner network */}
                {/* Call to Action (CTA) - Join the Partner Network */}
                <div className="flex flex-col items-center w-full bg-gray-100 text-center p-20 rounded py-40">
                    <h2 className="headline">
                        {t('partners.headline.title')}
                    </h2>
                    <span className="my-6 text-description md:w-1/2">
                        {t('partners.headline.description')}
                    </span>
                    <div className="flex flex-row items-center justify-center mt-4">
                        <Link to="/contact">
                            <button className="cta-button">{t('partners.cta')}</button>
                        </Link>
                    </div>  
                </div>

                {/* Section 2 - Why become a partner? - https://www.vanta.com/partners/become-a-partner */}
                {/* Why Become a Partner? */}
                <div className="flex flex-col w-full text-left my-10 p-4 lg:p-20">
                    {/* Title */}
                    <div className="flex flex-col">
                        <h3 className="text-4xl font-semibold mb-8 ">
                            {t('partners.why.title')} 
                        </h3>
                        {/* <span className="text-sm text-gray-800"> {t('partners.why.description')}  </span> */}
                    </div>

                    {/* Photo + Bullets */}
                    <div className="flex lg:flex-row flex-col items-start justify-center space-y-12 lg:space-y-0 lg:space-x-4 w-full ">
                        <div className="flex flex-col w-full lg:w-1/2 text-left space-y-12">
                                <span className="text-description w-full">
                                    {t('partners.why.description')}
                                </span>
                            <ul className="list-none list-inside text-gray-800 space-y-4 ">
                                <li className="flex flex-col space-y-2 border-b-2 border-b-gray-200 pb-4">
                                    <b className="flex text-lg">🌐 {t('partners.why.bullet-1.title')}</b>
                                    <span className="flex"> {t('partners.why.bullet-1.description')}</span>
                                </li>
                                <li className="flex flex-col space-y-2 border-b-2 border-b-gray-200 pb-4">
                                    <b className="flex text-lg">🤝 {t('partners.why.bullet-2.title')}</b>
                                    <span className="flex"> {t('partners.why.bullet-2.description')}</span>
                                </li>
                                <li className="flex flex-col space-y-2 border-b-2 border-b-gray-200 pb-4">
                                    <b className="flex text-lg">⚡ {t('partners.why.bullet-3.title')}</b>
                                    <span className="flex"> {t('partners.why.bullet-3.description')}</span>
                                </li>  
                            </ul>
                        </div>

                        <div className="flex flex-col items-center lg:w-1/2 w-full"> 
                            <img className="object-contain max-h-[500px] rounded-full" 
                                src="https://www.dropbox.com/scl/fi/1jiq5u8y3edc8qau59303/Kincode-App-Partners-Kincode-meeting.png?rlkey=fp4aii35acktl8muk4ynel0ny&raw=1" />
                        </div>
                           
                    </div>
                </div>

                {/* Examples of Partner Interventions */}
                <div className="flex flex-col w-full text-center p-20 lg:space-y-12">
                    {/* Title */}
                    {/* Title */}
                    <div className="flex flex-col">
                        <h3 className="text-4xl font-semibold mb-8 ">
                            {t('partners.interventions.title')} 
                        </h3>
                        <span className="text-description"> 
                            {t('partners.interventions.description')}  
                        </span>
                    </div>

                    {/* Photo + Bullets */}
                    <div className="flex lg:flex-row flex-col items-center justify-center space-y-12 lg:space-y-0 lg:space-x-4 w-full ">
                        <div className="flex flex-col items-center lg:w-1/2 w-full"> 
                            <img className="object-contain max-h-[500px] rounded-full" 
                                src="https://www.dropbox.com/scl/fi/po4hcb18iaq4253lzuua2/Kincode-App-Partners-Office-building-positive.png?rlkey=qetuc99rfzzlzdsg2uhse531h&raw=1" />
                        </div>
                        <div className="flex flex-col w-full lg:w-1/2 text-left space-y-2">
                                <span className="text-description w-full">
                                    {t('partners.interventions.description-2')}
                                </span>
                            <ul className="list-none list-inside text-gray-800 space-y-4 ">
                                <li className="flex flex-row items-start space-x-4 border-b-2 border-t-2 border-b-gray-200 pb-4 pt-4">
                                    <div className="flex text-6xl">
                                        1.
                                    </div>
                                    <div className="flex flex-col">
                                        <b className="flex text-lg">{t('partners.interventions.bullet-1.title')}</b>
                                        <span className="flex"> {t('partners.interventions.bullet-1.description')}</span>
                                    </div>
                                </li>
                                <li className="flex flex-row items-start space-x-4 border-b-2 border-b-gray-200 pb-4 pt-4">
                                    <div className="flex text-6xl">
                                        2.
                                    </div>
                                    <div className="flex flex-col">
                                        <b className="flex text-lg">{t('partners.interventions.bullet-2.title')}</b>
                                        <span className="flex"> {t('partners.interventions.bullet-2.description')}</span>
                                    </div>
                                </li>
                                <li className="flex flex-row items-start space-x-4 border-b-2 border-b-gray-200 pb-4 pt-4">
                                    <div className="flex text-6xl">
                                        3.
                                    </div>
                                    <div className="flex flex-col">
                                        <b className="flex text-lg">{t('partners.interventions.bullet-3.title')}</b>
                                        <span className="flex"> {t('partners.interventions.bullet-3.description')}</span>
                                    </div>
                                </li>
                                <li className="flex flex-row items-start space-x-4 border-b-2 border-b-gray-200 pb-4 pt-4">
                                    <div className="flex text-6xl">
                                        4.
                                    </div>
                                    <div className="flex flex-col">
                                        <b className="flex text-lg">{t('partners.interventions.bullet-4.title')}</b>
                                        <span className="flex"> {t('partners.interventions.bullet-4.description')}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>


                    </div>

                </div>

                {/* Section 3 - Our partners - EMPTY FOR NOW - https://www.vanta.com/partners/partner-network || https://www.vanta.com/partners/find-a-partner */}

                {/* Section 4 - Our partner's feedback - EMPTY FOR NOW */}


                {/* CTA - Join */}
                <div className="flex flex-col lg:items-start items-center text-center my-10 p-20">
                <h2 className="headline">{t('partners.headline.title')}</h2>
                    <Link to="/contact">
                        <button className="bg-green-200 text-black font-medium px-6 py-4 rounded-lg shadow-lg hover:bg-green-600">{t('partners.cta')}</button>
                    </Link>
                </div>



            </div>

            <Footer/>
        </React.Fragment>
    )
}

export default Partners;
