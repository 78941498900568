import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// Pages to Route
import About from '../public_pages/about';
import TermsAndConditions from '../public_pages/terms';
import PrivacyPolicy from '../public_pages/privacy';
import NothingThere from '../public_pages/nothingThere';

import Product from '../public_pages/product';
import Security from '../public_pages/security';
import Pricing  from '../public_pages/pricing';
import Roadmap from "../public_pages/roadmap";

import Partners from "../public_pages/partners";

import Home from '../public_pages/home';
import Contact from "../public_pages/contact";

// Engage
import Engage from "../public_pages/product/engage";
import Pulse from "../public_pages/product/engage/pulse";
import Meetings from "../public_pages/product/engage/meetings";
import Recognition from "../public_pages/product/engage/recognition";

// Perform
import Perform from "../public_pages/product/perform";
import Performance from "../public_pages/product/perform/performance";
import Calibration from "../public_pages/product/perform/calibration";
// import Goals from "../public_pages/product/perform/goals";

// Develop
import Develop from "../public_pages/product/develop";

// Basics
import Basics from "../public_pages/product/basics";
import Profile from "../public_pages/product/basics/profile";
import Organigram from "../public_pages/product/basics/organigram";
import TimeOff from "../public_pages/product/basics/timeoff";
import Inbox from "../public_pages/product/basics/inbox";
import Onboarding from "../public_pages/product/develop/onboarding";

// AI
import AI from "../public_pages/product/ai/ai";

// Use cases
import ToxicCulture from "../public_pages/useCases/toxicCulture";


// --------------------------------------------------------------------------------------------
function Routing () {
    const location = useLocation();
    const background = location.state && location.state.background;

    return (
    <React.Fragment>
    {/* Pass location to Routes so that Page remains background when ModalVersion is opened */}
        <Routes location={background || location}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />

            {/* Product pages */}
            <Route path="/product" element={<Product />} />
            
            {/* Engage */}
            <Route path="/product/engage" element={<Engage />} />
            <Route path="/product/engage/pulse" element={<Pulse />} />
            <Route path="/product/engage/meetings" element={<Meetings />} />
            <Route path="/product/engage/recognition" element={<Recognition />} />

            {/* Performance */}
            <Route path="/product/perform" element={<Perform />} />
            <Route path="/product/perform/performance" element={<Performance />} />
            <Route path="/product/perform/calibration" element={<Calibration />} />
            {/* <Route path="/product/perform/goals" element={<Goals />} /> */}

            {/* Development */}
            <Route path="/product/develop" element={<Develop />} />
            <Route path="/product/develop/onboarding" element={<Onboarding />} />

            {/* Basics */}
            <Route path="/product/basics" element={<Basics />} />
            <Route path="/product/basics/profile" element={<Profile />} />
            <Route path="/product/basics/organigram" element={<Organigram />} />
            <Route path="/product/basics/timeoff" element={<TimeOff />} />
            <Route path="/product/basics/inbox" element={<Inbox />} />

            {/* AI */}
            <Route path="/product/ai" element={<AI />} />

            {/* Use cases */}
            <Route path="/useCases/toxicCulture" element={<ToxicCulture />} />

            {/* Other pages */}
            <Route path="/security" element={<Security />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/partners" element={<Partners/>}/>
            <Route path="*" element={<NothingThere/>}/>
        </Routes>
        
    {/* Open Modal if state.background is defined */}
    {background && (
        <Routes>

        </Routes>
      )}            

    </React.Fragment>
    );

}

export default Routing;

  

  