// React
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

// Common
import Header from '../../../common/newHeader';
import Footer from '../../../common/footer';

// 3P
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

// Widgets
import Testimonials from '../../../widgets/testimonials';
import CTA from '../../../widgets/cta';
import CustomersWidget from '../../../widgets/customers';
import TextAndImage from '../../../widgets/textAndimage';

function Onboarding () {
  const location = useLocation();
  const {t} = useTranslation();


// ------------------------------------------------------------------------------------------
// Functions
// ------------------------------------------------------------------------------------------
useEffect (() => {
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: "Product-Develop-Onboarding" });

    // Scroll to top
    window.scrollTo(0, 0);
  
  }, []);


  // ------------------------------------------------------------------------------------------
  // Render function
  // ------------------------------------------------------------------------------------------
  return(
      <React.Fragment>
        <Header/>

        <div className='flex flex-col items-center h-auto w-auto'>
            {/* Headline */}
            <div className='flex flex-col space-y-12 lg:space-y-0 lg:flex-row items-center lg:justify-center w-full px-8 lg:px-40 py-24 bg-green-100'>
                {/* Text left-side */}
                <div className='flex flex-col items-start w-full lg:w-1/2 space-y-4'>
                    {/* Section title */}
                    <h1 className='text-left text-lg font-bold text-[#63C7AA]'>{t('product.onboarding.title')}</h1>
                    {/*  Section description */}

                    {/* Section key message */}
                    <h2 className='widget-keyMessage-title'>{t('product.onboarding.keyMessage')}</h2>

                    {/* Section description */}
                    <p className='widget-keyMessage-description'>{t('product.onboarding.keyMessage.description')}</p>

                    {/* Section key bullet points */}
                    <div className='flex flex-col items-start text-left space-y-4 justify-between w-full pt-4'>
                        <div className='widget-bulletPoints'><span className='mr-4'>🛠</span>{t('product.onboarding.bullet1')}</div>
                        <div className='widget-bulletPoints'><span className='mr-4'>🤝</span>{t('product.onboarding.bullet2')}</div>
                        <div className='widget-bulletPoints'><span className='mr-4'>📚</span>{t('product.onboarding.bullet3')}</div>
                    </div>


                </div>

                {/* Image right side */}
                <div className='flex flex-col items-center justify-center w-full lg:w-2/3 pt-8'>
                    <div className='container content-fit mx-auto lg:w-2/3 max-w-sm lg:max-w-xl overflow-hidden'>
                        <img className='rounded-lg object-cover w-full h-auto shadow rounded-lg' 
                            alt="Kincode App - engagement platform " 
                            src="/assets/illustrations/onboarding/development-onboarding-illustration.webp" />
                    </div>
                </div>

            </div>

            {/* Customers */}
            <CustomersWidget />

            {/* Set up for success */}
            <TextAndImage
                      title={t('product.onboarding.start.title')}
                      keyMessage={t('product.onboarding.start.keyMessage')}
                      keyMessageDescription={t('product.onboarding.start.keyMessage.description')}
                      bulletPoint1={t('product.onboarding.start.bullet1')} bulletPoint1Emoji="🛠️"
                      bulletPoint2={t('product.onboarding.start.bullet2')} bulletPoint2Emoji="📚"
                      bulletPoint3={t('product.onboarding.start.bullet3')} bulletPoint3Emoji="🎯"
                      img=""
                      alt="Kincode App - engagement platform"
                    //   urlLearnMore={'/product/develop/'}
                      orientation="left"
                    />

                    
            {/* Cultura de inclusión y pertenencia */}
            <TextAndImage
                      title={t('product.onboarding.culture.title')}
                      keyMessage={t('product.onboarding.culture.keyMessage')}
                      keyMessageDescription={t('product.onboarding.culture.keyMessage.description')}
                      bulletPoint1={t('product.onboarding.culture.bullet1')} bulletPoint1Emoji="🛠️"
                      bulletPoint2={t('product.onboarding.culture.bullet2')} bulletPoint2Emoji="📚"
                      bulletPoint3={t('product.onboarding.culture.bullet3')} bulletPoint3Emoji="🎯"
                      img=""
                      alt="Kincode App - engagement platform"
                    //   urlLearnMore={'/product/develop/'}
                      orientation="right"
                    />
            

            {/* Testimonials  */}
            <Testimonials />

            {/* Seguimiento */}
            <TextAndImage
                      title={t('product.onboarding.tracking.title')}
                      keyMessage={t('product.onboarding.tracking.keyMessage')}
                      keyMessageDescription={t('product.onboarding.tracking.keyMessage.description')}
                      bulletPoint1={t('product.onboarding.tracking.bullet1')} bulletPoint1Emoji="🛠️"
                      bulletPoint2={t('product.onboarding.tracking.bullet2')} bulletPoint2Emoji="📚"
                      bulletPoint3={t('product.onboarding.tracking.bullet3')} bulletPoint3Emoji="🎯"
                      img=""
                      alt="Kincode App - engagement platform"
                    //   urlLearnMore={'/product/develop/'}
                      orientation="left"
                    />
            

            {/* Book a demo - or free trial CTA */}
            <CTA />

        </div>
        
        
        <Footer/>

      </React.Fragment>
  );

}

export default Onboarding;