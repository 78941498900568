export const headersInfo = [
    // {name:"header.home", url:"/"},
    {name:"header.product", id:"product", submenu: [
      { 
        sectionName: 'home.pricing.tier-1.title', url:"/product/engage/", subSections: [
          { id: '1a', title: 'home.pricing.feature.pulse', url: '/product/engage/pulse/', icon:"Pulse" },
          { id: '1b', title: 'home.pricing.feature.1-1s', url: '/product/engage/meetings/', icon:"1on1" },
          { id: '1c', title: 'home.pricing.feature.recognition', url: '/product/engage/recognition/', icon:"Recognition" },
       ]
      },
      { 
        sectionName: 'home.pricing.tier-2.title', url:"/product/perform/", subSections: [
          { id: '2a', title: 'home.pricing.feature.performance', url: '/product/perform/performance/' , icon: "Performance-Review"},
          { id: '1b', title: 'home.pricing.feature.calibration', url: '' , icon: "Calibration"},
          { id: '1c', title: 'home.pricing.feature.goals', url: '' , icon: "Goals"},
       ]
      },
      { 
        sectionName: 'home.pricing.tier-3.title', url:"/product/develop/", subSections: [
          { id: '3a', title: 'home.pricing.feature.onboarding', url: '/product/develop/onboarding/', icon: "Onboarding" },
          { id: '1b', title: 'home.pricing.feature.recruitment', url: '' , icon: "Recruitment"},
          { id: '1c', title: 'home.pricing.feature.development', url: '', icon: "Development"},
          { id: '1d', title: 'home.pricing.feature.career', url: '' , icon: "Career"},
       ]
      },
      { 
        sectionName: 'home.pricing.basics.title', url:"/product/basics/", subSections: [
          { id: '4a', title: 'home.pricing.feature.profile', url: '/product/basics/profile/', icon: "Profile" },
          { id: '4b', title: 'home.pricing.feature.organigram', url: '/product/basics/organigram/' , icon: "Organigram"},
          { id: '4c', title: 'home.pricing.feature.holidays', url: '/product/basics/timeoff/', icon: "Time-off"},
          { id: '4d', title: 'home.pricing.feature.inbox', url: '/product/basics/inbox/' , icon: "Inbox"},
          // { id: '4e', title: 'home.pricing.feature.timeTracking', url: '/product/basics/timeTracking/' , icon: "Time-Tracking"},
       ]
      }
    ]},
    // {name:"header.solutions", id:"solutions", submenu: [
    //   { 
    //     sectionName: 'home.pricing.tier-1.title', url:"/solutions/engage/", subSections: [
    //       { id: '1a', title: 'home.pricing.feature.pulse', url: '/product/engage/pulse/', icon:"Pulse" },
    //       { id: '1b', title: 'home.pricing.feature.1-1s', url: '/product/engage/meetings/', icon:"1on1" },
    //       { id: '1c', title: 'home.pricing.feature.recognition', url: '/product/engage/recognition/', icon:"Recognition" },
    //    ]
    //   }],  
    // },
    {name:"header.useCases", url:"/useCases/toxicCulture/"},
    {name:"header.pricing", url:"/pricing/"},
    {name:"header.contact", url:"/contact/"},
  ];
