// React
import React, { useRef } from 'react';
import useOnScreen from '../hooks/useOnScreen'; // Adjust the path based on your file structure


// ------------------------------------------------------------------------------------------
// Component
// ------------------------------------------------------------------------------------------
const AppearingImage = ({ img, alt }) => {

const ref = useRef();
const isVisible = useOnScreen(ref);

  return (
      <img
        ref={ref}
        src={img}
        alt={alt}
        className={`rounded-lg object-cover w-full h-auto overflow-hidden rounded-lg ${isVisible ? 'animate-quick-bounce' : 'opacity-0'}`}
      />
    );
};

export default AppearingImage;
