// React
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Common and headers
import Footer from '../common/footer';
import Header from '../common/newHeader';

// 3P
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';

// Icons
import {BiSad} from 'react-icons/bi';

function  NothingThere () {
    const location = useLocation();

    useEffect (() => {
        // Send pageview with a custom path
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: "404" });

        // Scroll to top
        window.scrollTo(0, 0);
    }, []);

    // Variables
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <Header/>
            <div className='flex flex-col justify-center h-full'>
                <h2 className='flex flex-row text-4xl font-bold  m-32'>
                    <span className='text-green-600 text-xl'> {t('error.404')} </span>
                    <span className='ml-4 mr-4 text-6xl'>|</span>
                    <span> {t('error.404.nothing')}  </span> 
                    <BiSad className='ml-4'/>
                </h2>

                <span className='flex m-32'>
                    <Link to="/" className='underline font-bold '> {t('error.404.back')} </Link>
                </span>
                
            </div>
            <Footer/>
        </React.Fragment>
    );
}

export default NothingThere;